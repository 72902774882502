/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import humps from 'humps';

import { getServices, getService } from 'common/lib/storeServices';
import { ServiceType } from 'common/types/ServiceType';

type StoreState = {
  isFetching: boolean;
  services: ServiceType[];
  currentService: ServiceType | {};
};

export const initialState: StoreState = {
  isFetching: true,
  services: [],
  currentService: {},
};

export const fetchServices = createAsyncThunk(
  'store/fetchServices',
  async (token) => {
    const response = await getServices(token);
    // @ts-ignore
    const services: ServiceType[] = humps.camelizeKeys(response);
    return services;
  }
);

export const fetchService = createAsyncThunk(
  'store/fetchService',
  async ({ token, id }: { token: string; id: number }) => {
    const response = await getService(token, id);
    //  @ts-ignore
    const item: ServiceType = humps.camelizeKeys(response);
    const { service, state, pk } = item;
    return { ...service, state, pk };
  }
);

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    loadStore(state, action) {
      state.services = action.payload;
    },
    loadItem(state, action) {
      state.currentService = action.payload;
    },
    fetchingStore(state, action) {
      state.isFetching = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServices.fulfilled, (state, action) => {
        state.services = action.payload;
        state.isFetching = false;
      })
      .addCase(fetchService.fulfilled, (state, action) => {
        state.currentService = action.payload;
        state.isFetching = false;
      });
  },
});

export const { loadStore, loadItem, fetchingStore } = storeSlice.actions;

export default storeSlice.reducer;
