import { Result, Button, Space } from 'antd';

import { analytics } from 'common/analytics/analytics';
import {
  REFRESH_CLICKED,
  CONTACT_BUTTON_CLICKED,
} from 'common/analytics/events';
import { openLinkInNewTab } from 'common/utils/urls';

// export type ErrorFallbackProps = {
//   resetErrorBoundary: Function;
// };

declare let window: {
  analytics: any;
  location: any;
};

const handleRefreshPage = () => {
  analytics.track(REFRESH_CLICKED);
  window.location.reload();
};

const handleContactSupport = () => {
  analytics.track(CONTACT_BUTTON_CLICKED);
  openLinkInNewTab('https://www.govchain.co.za/contact');
};

const ErrorFallback = () => {
  return (
    <Result
      status="500"
      title="Error :("
      subTitle="Oops, something went wrong. Try refresh the page or contact
          support if the problem persists."
      extra={
        <div>
          <Space>
            <Button type="primary" onClick={() => handleRefreshPage()}>
              Refresh Page
            </Button>
            <Button onClick={() => handleContactSupport()}>
              Contact Support
            </Button>
          </Space>
        </div>
      }
    />
  );
};

export default ErrorFallback;
