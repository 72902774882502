import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import companiesReducer from './companies';
import directorProfileReducer from './directorProfile';
import directorsReducer from './directors';
import onboardingReducer from './onboarding';
import shopReducer from './shop';
import storeReducer from './store';
import uiReducer from './ui';
import userReducer from './user';
import { companyProfileApi } from 'common/lib/companyProfileService';

const createRootReducer = (history) =>
  combineReducers({
    ui: uiReducer,
    user: userReducer,
    companies: companiesReducer,
    onboarding: onboardingReducer,
    shop: shopReducer,
    directors: directorsReducer,
    directorProfile: directorProfileReducer,
    store: storeReducer,
    [companyProfileApi.reducerPath]: companyProfileApi.reducer,
    router: connectRouter(history),
  });

export default createRootReducer;
