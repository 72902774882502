import { Form, Button } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { SignupFormValues } from '../types';
import { analytics } from 'common/analytics/analytics';
import { SIGNUP_CLICKED, SIGNUP_FORM_ERROR } from 'common/analytics/events';
import EmailField from 'common/components/Forms/EmailField';
import MobileNumberField from 'common/components/Forms/MobileNumberField';

import NameFieldSet from 'common/components/Forms/NameFieldSet';

const PROMOURL = process.env.REACT_APP_PROMO_URL;

const FormItem = Form.Item;

const Wrapper = styled.div`
  .signup-terms {
    margin-bottom: 24px;
  }
`;

const ButtonsWrapper = styled.div`
  button {
    width: 100%;
  }
`;

export type SignupFormProps = {
  isSubmitting: boolean;
  onSuccess: (values: any) => void;
};

export const SignupForm = ({ isSubmitting, onSuccess }: SignupFormProps) => {
  const [form] = Form.useForm();

  const onFinish = (values: SignupFormValues) => {
    analytics.track(SIGNUP_CLICKED, { category: 'Account', values });
    onSuccess(values);
  };

  const onFinishFailed = ({ errorFields }: { errorFields: any }) => {
    analytics.track(SIGNUP_FORM_ERROR, {
      category: 'Account',
      errorFields,
    });
    form.scrollToField(errorFields[0].name);
  };
  return (
    <Wrapper>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="signup-form"
        form={form}
        labelCol={{ span: 6 }}
        labelAlign="right"
      >
        <NameFieldSet />
        <EmailField id="email" label="Email" size="large" required />
        <MobileNumberField />
        <div className="signup-terms">
          By clicking Create Account You agree to Govchain&apos;s{' '}
          <a target="blank" href={`${PROMOURL}legal`}>
            Terms and Conditions
          </a>
        </div>
        <ButtonsWrapper>
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              size="large"
              loading={isSubmitting}
              id="signup-button"
            >
              {isSubmitting ? 'Loading' : 'Create Account'}
            </Button>
          </FormItem>
          <p>
            Already have a Govchain account? <Link to="/login">Login</Link>
          </p>
        </ButtonsWrapper>
      </Form>
    </Wrapper>
  );
};

export default SignupForm;
