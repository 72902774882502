export const createBirthDate = (
  day: string,
  month: string,
  year: string | number
) => `${year}-${month}-${day}`;

export const splitBirthDate = (birthDate: string) => {
  const [birthYear, birthMonth, birthDay] = birthDate.split('-');

  return {
    birthDay,
    birthMonth,
    birthYear: parseInt(birthYear, 10),
  };
};
