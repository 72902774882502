import styled from 'styled-components';

import AfricaImage from './africa.png';

const Wrapper = styled.div`
  text-align: right;
  @media (max-width: 768px) {
    text-align: right;
  }
  img {
    margin-left: 5px;
    height: 18px;
  }
`;

const MadeIn = () => (
  <Wrapper>
    {/* eslint-disable react/jsx-one-expression-per-line */}
    Made in Africa <img src={AfricaImage} alt="africa" />
  </Wrapper>
);

export default MadeIn;
