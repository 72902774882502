/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CloseCircleFilled } from '@ant-design/icons';
import { Modal, Button } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

declare let window: {
  Intercom: any;
};

const Title = styled.div`
  .anticon {
    color: #f5222d;
    margin-right: 8px;
  }
`;

const DuplicateErrorComponent = () => {
  return (
    <div>
      That email address is already registered on Govchain. Please{' '}
      <Link to="/login">login</Link> or use a different email.
    </div>
  );
};

const DefaultErrorComponent = () => {
  return (
    <div>
      There was an error creating your account. Please{' '}
      <a onClick={() => window.Intercom('show')}>contact support</a>.
    </div>
  );
};

const ERROR_TYPES = {
  409: {
    title: 'Duplicate Account',
    component: <DuplicateErrorComponent />,
  },
  default: {
    title: 'Error',
    component: <DefaultErrorComponent />,
  },
};

export type ErrorModalProps = {
  visible: boolean;
  errorCode: number;
  onClose: () => void;
};

const ErrorModal = ({ visible, errorCode, onClose }: ErrorModalProps) => {
  const currentError = () => {
    if (errorCode === 409) {
      return ERROR_TYPES[409];
    }
    return ERROR_TYPES.default;
  };
  const { title, component } = currentError();
  return (
    <Modal
      title={
        <Title>
          <CloseCircleFilled />
          {title}
        </Title>
      }
      open={visible}
      onOk={() => onClose()}
      onCancel={() => onClose()}
      closable={false}
      footer={[
        <Button type="primary" key="back" onClick={() => onClose()}>
          Ok
        </Button>,
      ]}
    >
      {component}
    </Modal>
  );
};

export default ErrorModal;
