import { geekblue, green, red, gold } from '@ant-design/colors';

const colors = {
  primary: geekblue.primary,
  success: green.primary,
  error: red[4],
  warning: gold.primary,
  'border-color': '#e8e8e8',
};

export const theme = {
  colors,
};
