import { MailOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import {
  validateEmailAddress,
  normalizeEmail,
} from 'common/utils/emailValidation';

const FormItem = Form.Item;

export type EmailFieldProps = {
  id?: string;
  label?: string;
  size?: 'large' | 'middle' | 'small';
  required?: boolean;
};

export const EmailField = ({
  id = 'email',
  label = 'Email',
  size = 'large',
  required = true,
  ...props
}: EmailFieldProps) => (
  <FormItem
    name={id}
    label={label}
    validateFirst
    rules={[{ required }, { required, validator: validateEmailAddress }]}
    normalize={(value) => normalizeEmail(value)}
    {...props}
  >
    <Input
      size={size}
      prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
    />
  </FormItem>
);

export default EmailField;
