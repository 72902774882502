import humps from 'humps';
import {
  getDirector,
  updateDirector,
  submitKyc,
} from '../lib/directorServices';
import { createBirthDate, splitBirthDate } from 'common/utils/createBirthDate';

const initialState = {
  profile: {
    firstName: '',
    lastName: '',
    email: '',
    identityNumber: '',
    inviteId: '',
    isAccountOwner: false,
    state: '',
    userKycState: '',
    companyName: '',
    incorporatorFirstName: '',
    incorporatorLastName: '',
    phoneNumber: '',
    physicalStreet: '',
    physicalBuilding: '',
    physicalCity: '',
    physicalProvince: '',
    physicalPostCode: '',
    postalStreet: '',
    postalBuilding: '',
    postalCity: '',
    postalProvince: '',
    postalPostCode: '',
    originCountry: '',
    birthDay: '',
    birthMonth: '',
    birthYear: '',
    agentLpoaContent: '',
  },
  documents: [],
};

export const initDirectorPayload = (values) => {
  const {
    member,
    inviteId,
    isAccountOwner,
    state,
    companyName,
    agentLpoaContent,
  } = values;
  const { firstName, lastName, email, profile } = member;
  const {
    identityNumber,
    phoneNumber,
    physicalStreet,
    physicalBuilding,
    physicalCity,
    physicalProvince,
    physicalPostCode,
    postalStreet,
    postalBuilding,
    postalCity,
    postalProvince,
    postalPostCode,
    birthDate,
    originCountry,
  } = profile;
  let payload = {
    firstName,
    lastName,
    email,
    identityNumber,
    inviteId,
    isAccountOwner,
    state,
    userKycState: 'kyc_submitted',
    companyName,
    phoneNumber,
    physicalStreet,
    physicalBuilding,
    physicalCity,
    physicalProvince,
    physicalPostCode,
    postalStreet,
    postalBuilding,
    postalCity,
    postalProvince,
    postalPostCode,
    agentLpoaContent,
    birthDate,
    originCountry,
  };
  if (birthDate) {
    payload = {
      ...payload,
      ...splitBirthDate(birthDate),
    };
  }
  return payload;
};

export const createDirectorProfilePayload = (values) => {
  const {
    firstName,
    lastName,
    phoneNumber,
    identityNumber,
    physicalStreet,
    physicalBuilding,
    physicalCity,
    physicalProvince,
    physicalPostCode,
    postalStreet,
    postalBuilding,
    postalCity,
    postalProvince,
    postalPostCode,
  } = values;
  let payload = {};
  payload = {
    firstName,
    lastName,
    phoneNumber,
    identityNumber,
    physicalStreet,
    physicalBuilding,
    physicalCity,
    physicalProvince,
    physicalPostCode,
    postalStreet: postalStreet || physicalStreet,
    postalBuilding: postalBuilding || physicalBuilding,
    postalCity: postalCity || physicalCity,
    postalProvince: postalProvince || physicalProvince,
    postalPostCode: postalPostCode || physicalPostCode,
  };
  if (values.postalStreet) {
    payload = {
      ...payload,
      postalStreet,
      postalBuilding,
      postalCity,
      postalProvince,
      postalPostCode,
    };
  }
  if (values.originCountry) {
    const { originCountry, birthDay, birthMonth, birthYear } = values;
    payload = {
      ...payload,
      originCountry,
      birthDate: createBirthDate(birthDay, birthMonth, birthYear), // add helper function here to convert date of birth
    };
  }
  const formattedPayload = humps.decamelizeKeys(payload);
  return formattedPayload;
};

export const DIRECTOR_PROFILE_LOAD = 'DIRECTOR_PROFILE_LOAD';
export const DIRECTOR_PROFILE_UPDATE = 'DIRECTOR_PROFILE_UPDATE';
export const DIRECTOR_DOCUMENTS_UPDATE = 'DIRECTOR_DOCUMENTS_UPDATE';

export const loadDirectorProfile = (director) => ({
  type: DIRECTOR_PROFILE_LOAD,
  payload: director,
});
export const updateDirectorProfile = (director) => ({
  type: DIRECTOR_PROFILE_UPDATE,
  payload: director,
});
export const updateDirectorDocuments = (documents) => ({
  type: DIRECTOR_DOCUMENTS_UPDATE,
  payload: documents,
});

export const fetchDirectorProfile = (inviteId) => {
  return async (dispatch) => {
    try {
      const directorProfile = await getDirector(inviteId);
      const formattedDirectorProfile = humps.camelizeKeys(directorProfile);
      const formattedPayload = initDirectorPayload(formattedDirectorProfile);
      await dispatch(loadDirectorProfile(formattedPayload));
      return formattedPayload;
    } catch (error) {
      return Error('Error fetching and parsing data', error);
    }
  };
};

export const saveDirectorProfile = (director, token) => {
  return async (dispatch) => {
    try {
      const payload = createDirectorProfilePayload(director);
      const response = await updateDirector(payload, token);
      await dispatch(updateDirectorProfile(director));
      return response;
    } catch (error) {
      return Error('Error fetching and parsing data', error);
    }
  };
};

export const saveDirectorDocuments = (documents, token, inviteId) => {
  return async (dispatch) => {
    try {
      await submitKyc(documents, token, inviteId);
      const formattedDirectorDocuments = humps.camelizeKeys(documents);
      await dispatch(updateDirectorDocuments(formattedDirectorDocuments));
      return formattedDirectorDocuments;
    } catch (error) {
      return Error('Error fetching and parsing data', error);
    }
  };
};

const directorProfile = (state = initialState, action) => {
  switch (action.type) {
    case DIRECTOR_PROFILE_LOAD:
      return { ...state, profile: action.payload };
    case DIRECTOR_PROFILE_UPDATE:
      return { ...state, profile: { ...state.profile, ...action.payload } };
    case DIRECTOR_DOCUMENTS_UPDATE:
      return { ...state, documents: action.payload };
    default:
      return state;
  }
};

export default directorProfile;
