import React from 'react';
import { Form, Input } from 'antd';

const { Item: FormItem } = Form;

interface NameFieldSetProps {
  initialValues?: {
    firstName: string;
    lastName: string;
  };
  size?: 'large' | 'middle' | 'small';
  required?: boolean;
}

const NameFieldSet: React.FC<NameFieldSetProps> = ({
  initialValues = { firstName: '', lastName: '' },
  size = 'large',
  required = true,
}) => {
  const { firstName, lastName } = initialValues;

  return (
    <>
      <FormItem
        name="firstName"
        label="First Name"
        rules={[
          { required },
          {
            // eslint-disable-next-line no-useless-escape
            pattern: /^[^\[\]{}()]*$/,
            message: 'Special characters are not allowed',
          },
        ]}
        initialValue={firstName}
      >
        <Input id="firstName" size={size} autoComplete="given-name" />
      </FormItem>
      <FormItem
        name="lastName"
        label="Surname"
        rules={[{ required }]}
        initialValue={lastName}
      >
        <Input id="lastName" size={size} autoComplete="family-name" />
      </FormItem>
    </>
  );
};

export default NameFieldSet;
