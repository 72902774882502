import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import humps from 'humps';

import { BBBEELevel } from 'common/types/Enums';

const baseUrl = process.env.REACT_APP_API_URL;

export type CompanyProfileType = {
  companyReservedName: string;
  companyRegistrationNum: string;
  taxReferenceNum: string;
  physicalAddress?: string;
  contactPhone?: string;
  contactEmail?: string;
  businessDescription?: string;
  aboutUs?: string;
  whyChooseUs?: string;
  servicesProvided?: string[];
  ourTeam?: string;
  beeLevel?: BBBEELevel;
};

export const companyProfileApi = createApi({
  reducerPath: 'companyProfileApi',
  baseQuery: fetchBaseQuery({ baseUrl }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getCurrentCompanyProfile: builder.query<CompanyProfileType, string>({
      query: (token) => ({
        url: '/profiles/profilegenerator/current/',
        headers: { Authorization: `Token ${token}` },
      }),
      transformResponse: (rawResult: CompanyProfileType) => {
        const raw: any = humps.camelizeKeys(rawResult);
        const profile = {
          ...raw,
          servicesProvided:
            (raw.servicesProvided && raw.servicesProvided.split(', ')) || [],
          beeLevel: raw.beeLevel || 1,
        };
        return profile as CompanyProfileType;
      },
    }),
    updateCurrentCompanyProfile: builder.mutation<
      CompanyProfileType,
      {
        token: string;
        // @ts-ignore
        updates: Partial<CompanyProfileType>;
      }
    >({
      query: ({ token, updates }) => {
        const body = {
          ...humps.decamelizeKeys(updates),
          services_provided: updates.servicesProvided?.join(', '),
        };

        return {
          url: '/profiles/profilegenerator/current/',
          method: 'PATCH',
          headers: { Authorization: `Token ${token}` },
          body,
        };
      },
    }),
  }),
});

export const {
  useGetCurrentCompanyProfileQuery,
  useUpdateCurrentCompanyProfileMutation,
} = companyProfileApi;
