import { format } from 'date-fns';
import styled from 'styled-components';

const Wrapper = styled.div`
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Copyright = () => {
  const currentYear = format(new Date(), 'yyyy');
  return <Wrapper>© {currentYear} Govchain (Pty) Ltd</Wrapper>;
};

export default Copyright;
