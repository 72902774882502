import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import LoginForm from './components/LoginForm';
import analytics from 'common/analytics/analytics';
import { LOGIN_ERROR, LOGIN_SUCCESS } from 'common/analytics/events';
import AuthenticationPage from 'common/components/Page/AuthenticationPage/AuthenticationPage';
import { resendLogin } from 'common/lib/user';

interface LoginFormValues {
  email: string;
}

export const LoginPage: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>('');
  const [successMessage, setSuccessMessage] = useState<React.ReactNode>('');

  const handleSubmit = (values: LoginFormValues) => {
    const { email } = values;
    setIsSubmitting(true);
    setErrorMessage('');
    setSuccessMessage('');

    resendLogin(email)
      .then((response) => {
        analytics.track(LOGIN_SUCCESS, response);
        setIsSubmitting(false);
        setSuccessMessage(
          <div>
            We sent you an email with a link to log in. Go check your inbox!
          </div>
        );
      })
      .catch((response) => {
        analytics.track(LOGIN_ERROR, response);
        setIsSubmitting(false);
        setErrorMessage(
          <div>
            The email address that you&apos;ve entered doesn&apos;t match any
            account. <Link to="/signup">Sign up</Link> for an account or enter
            the correct email.
          </div>
        );
      });
  };

  return (
    <AuthenticationPage title="Login">
      <LoginForm
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        errorMessage={errorMessage}
        successMessage={successMessage}
      />
    </AuthenticationPage>
  );
};

export default LoginPage;
