import React from 'react';
import { IntlProvider } from 'react-intl';
import OptionalIntlConfig from 'react-intl/src/components/provider';

const DefaultIntlProvider = (
  props: React.PropsWithChildren<OptionalIntlConfig>
) => {
  // @ts-ignore
  const { locale, defaultLocale, ...rest } = props;
  return <IntlProvider locale="en" defaultLocale="en" {...rest} />;
};

export default DefaultIntlProvider;
