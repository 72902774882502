// @ts-ignore
import googleAnalytics from '@analytics/google-analytics';
// @ts-ignore
import intercomPlugin from '@analytics/intercom';
import Analytics from 'analytics';
import facebookPixelPlugin from './facebookPixel';
import gtagPlugin from './gtag';
import uetPlugin from './uet';
// import loggerPlugin from './logger';

// Check if the code is running in a browser environment
const IS_BROWSER = typeof window !== 'undefined';

// Check if the code is running in a test environment
const IS_TEST_ENV = process.env.NODE_ENV === 'test';

// Show plugins only in the browser environment and not in the test environment
const SHOW_PLUGINS = IS_BROWSER && !IS_TEST_ENV;

// Get environment variables
const GOOGLE_ANALYTICS_MEASUREMENT_ID = IS_TEST_ENV
  ? 'xxx'
  : process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;
const INTERCOM_APP_ID = IS_TEST_ENV
  ? 'xxx'
  : process.env.REACT_APP_INTERCOM_APP_ID;
const FACEBOOK_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
const GOOGLE_CONVERSION_ID = process.env.REACT_APP_GOOGLE_CONVERSION_ID;

// Initialize analytics and load plugins
const plugins = [
  googleAnalytics({
    measurementIds: [GOOGLE_ANALYTICS_MEASUREMENT_ID],
  }),
  intercomPlugin({
    appId: INTERCOM_APP_ID,
  }),
  gtagPlugin({
    conversionId: GOOGLE_CONVERSION_ID,
  }),
  facebookPixelPlugin({
    pixelId: FACEBOOK_PIXEL_ID,
  }),
  uetPlugin({
    xyz: '123',
  }),
  // loggerPlugin({
  //   xyz: '123',
  // }),
];

// Create an instance of Analytics with the plugins
export const analytics = Analytics({
  debug: false,
  plugins: SHOW_PLUGINS ? plugins : [],
});

// Make the Analytics instance available globally in the browser environment
// @ts-ignore
window.Analytics = analytics;

// Export the Analytics instance for usage throughout the app
export default analytics;
