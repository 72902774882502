const Maintenance = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
        textAlign: 'center',
        padding: '0 20px',
      }}
    >
      <div>
        <h1>Site Under Maintenance</h1>
        <p>We are currently working on improvements. Please check back soon.</p>
      </div>
    </div>
  );
};

export default Maintenance;
