export const generateRedirectUrl = (type: any) => {
  if (type === 'new') {
    return '/onboarding';
  }
  if (type === 'existing') {
    return '/companys/import';
  }

  return '/companys/add';
};
