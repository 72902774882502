import React from 'react';
import { Result } from 'antd';
import humps from 'humps';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { analytics } from 'common/analytics/analytics';
import { LOGIN_ERROR, LOGIN_SUCCESS } from 'common/analytics/events';
import LoadingPage from 'common/components/LoadingPage';
import { getDirector } from 'common/lib/directorServices';
import { clearUserData } from 'common/lib/user';
import { fetchUserAndCompanies } from 'common/reducers/user';

export const InviteLogin = () => {
  const [checking, setChecking] = React.useState(true);
  const [invalidInvite, setInvalidInvite] = React.useState(false);

  const [, setCookie] = useCookies(['cookie-name']);
  const dispatch = useDispatch();
  const history = useHistory();
  const { inviteId } = useParams<{
    inviteId: string;
  }>();

  const fetchDirectorAuthToken = React.useCallback(async () => {
    try {
      const director = await getDirector(inviteId);
      const formattedDirector = humps.camelizeKeys(director);
      // @ts-ignore
      const { authToken } = formattedDirector;
      return authToken;
    } catch {
      throw new Error('invalid invite id');
    }
  }, [inviteId]);

  const setTokenCookie = React.useCallback(
    (token: string) => {
      const domain = process.env.REACT_APP_DOMAIN || '';
      const expires = new Date(Number(new Date()) + 315360000000);
      setCookie('token', token, {
        domain,
        path: '/',
        expires,
      });
    },
    [setCookie]
  );

  const fetchUser = React.useCallback(
    async (authToken: any) => {
      const userPayload = await dispatch(fetchUserAndCompanies(authToken));
      // @ts-ignore
      const user = userPayload[0];
      return user;
    },
    [dispatch]
  );

  const identifyUser = React.useCallback(async (user: any) => {
    analytics.identify(user.uuid);
  }, []);

  const fetchData = React.useCallback(async () => {
    let authToken = '';
    try {
      authToken = await fetchDirectorAuthToken();
    } catch {
      analytics.track(LOGIN_ERROR);
      setChecking(false);
      return setInvalidInvite(true);
    }
    setTokenCookie(authToken);
    const user = await fetchUser(authToken);
    identifyUser(user);
    analytics.track(LOGIN_SUCCESS, 'Account');
    return history.push(`/director/verify/${inviteId}`);
  }, [
    history,
    fetchDirectorAuthToken,
    setTokenCookie,
    fetchUser,
    identifyUser,
    inviteId,
  ]);

  React.useEffect(() => {
    clearUserData();
    fetchData();
  }, [fetchData]);

  return (
    <>
      {checking && <LoadingPage />}
      {!checking && invalidInvite && (
        <Result
          status="error"
          title="Invalid Invite"
          subTitle="Your invite is either incorrect or expired. Please contact support."
        />
      )}
    </>
  );
};

export default InviteLogin;
