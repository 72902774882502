import axios from 'axios';

const BASEURL = process.env.REACT_APP_API_URL;

export const getCompanies = (token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${BASEURL}/companys/companys/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const getCurrentCompany = (currentCompanyId, token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${BASEURL}/companys/companys/${currentCompanyId}/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const setCurrentCompany = (companyId, token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${BASEURL}/companys/companys/${companyId}/set-current/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const updateCurrentCompany = (payload, currentCompanyId, token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'PATCH',
      url: `${BASEURL}/companys/companys/${currentCompanyId}/`,
      data: payload,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const submitCurrentCompany = (payload, currentCompanyId, token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'PUT',
      url: `${BASEURL}/companys/companys/${currentCompanyId}/submit/`,
      data: payload,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const addNewCompany = (token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${BASEURL}/companys/companys/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const getCompanyStatus = (currentCompanyId, token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${BASEURL}/companys/companys/${currentCompanyId}/statuslogs/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });
