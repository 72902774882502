// TODO: get a package that does this or turn it into a hook

const domain = process.env.REACT_APP_DOMAIN || '';
const expires = new Date(Number(new Date()) + 315360000000);

export const setToken = (cookies: any, token: string) =>
  cookies.set('token', token, {
    domain,
    path: '/',
    expires,
  });

export const deleteCookie = () => {
  document.cookie = 'token' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'; // eslint-disable-line
};
/* eslint-disable prefer-template  */
/* eslint-disable no-useless-concat  */
export const clearCookie = () => {
  const currentDomain = domain || document.domain;
  const path = '/';
  document.cookie =
    'token' +
    '=; expires=' +
    +new Date() +
    '; domain=' +
    currentDomain +
    '; path=' +
    path;
};
