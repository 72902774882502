import { MailOutlined, MessageOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { analytics } from 'common/analytics/analytics';
import {
  EMAIL_BUTTON_CLICKED,
  LIVE_CHAT_BUTTON_CLICKED,
} from 'common/analytics/events';

const Wrapper = styled.div`
  display: flex;
  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    flex-direction: column;
    text-align: center;
  }
`;

const Phone = styled.div`
  margin-right: 14px;
  display: flex;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    cursor: pointer;
    color: #2f54eb;
  }
  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

const PhoneLink = styled.span`
  margin-left: 6px;
`;

const LiveChat = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    cursor: pointer;
    color: #2f54eb;
  }
`;

const ChatLink = styled.span`
  margin-left: 6px;
`;

const handlePhoneLink = () => {
  analytics.track(EMAIL_BUTTON_CLICKED, {
    category: 'header',
  });
  window.open('mailto:support@govchain.co.za');
};

const handleLiveChatLink = () => {
  analytics.track(LIVE_CHAT_BUTTON_CLICKED, {
    category: 'header',
  });
  // @ts-ignore
  window.Intercom('show');
};

const Contact = () => {
  return (
    <Wrapper>
      <Phone onClick={() => handlePhoneLink()}>
        <MailOutlined />
        <PhoneLink>support@govchain.co.za</PhoneLink>
      </Phone>
      <LiveChat onClick={() => handleLiveChatLink()}>
        <MessageOutlined />
        <ChatLink>Start a live chat</ChatLink>
      </LiveChat>
    </Wrapper>
  );
};

export default Contact;
