export const BACK_BUTTON_CLICKED = 'Back Button Clicked';
export const EMAIL_BUTTON_CLICKED = 'Email Clicked';
export const LIVE_CHAT_BUTTON_CLICKED = 'Live Chat Clicked';
export const LOGOUT_CLICKED = 'Logout Clicked';

// UI
export const NEXT_CLICKED = 'Next Clicked';
export const SUBMIT_CLICKED = 'Submit Clicked';
export const BACK_CLICKED = 'Back Clicked';
export const REFRESH_CLICKED = 'Refresh Clicked';
export const CONTACT_BUTTON_CLICKED = 'Contact Button Clicked';

// Nav
export const CHANGE_COMPANY_CLICKED = 'Change Company Clicked';

// AUTHENTICATION

// Signup
export const SIGNUP_PAGE = 'Signup';
export const SIGNUP_CLICKED = 'Signup Clicked';
export const SIGNUP_FORM_ERROR = 'Signup Form Error';
export const ACCOUNT_SUBMITTED = 'Account Submitted';
export const ACCOUNT_CREATED = 'Account Created';
export const ACCOUNT_CREATED_EXISTING = 'Account Created Existing';
export const DUPLICATE_EMAIL = 'Duplicate Email';
export const ACCOUNT_CREATED_ERROR = 'Account Created Error';

// Invite Login
export const INVITE_LOGIN_PAGE = 'Invite Login';
export const LOGIN_ERROR = 'Login Error';
export const LOGIN_SUCCESS = 'Login Success';
export const LOGIN_FORM_SUBMITTED = 'Login Form Submitted';
export const LOGIN_FORM_ERROR = 'Login Form Error';

// NO COMPANIES
export const NO_COMPANIES_PAGE = 'No Companies';
export const NO_COMPANIES_CATEGORY = 'No Companies';

// SELECT COMPANY
export const SELECT_COMPANY_PAGE = 'Select Company';
export const SELECT_COMPANY_CATEGORY = 'Select Company';
export const SELECT_COMPANY_CLICKED = 'Select Company Clicked';

// ADD NEW COMPANY
export const ADD_NEW_COMPANY_PAGE = 'Add New Company';
export const ADD_NEW_COMPANY_CATEGORY = 'Add New Company';
export const ADD_NEW_COMPANY_NEW_CLICKED = 'Add New Company Clicked';
export const ADD_NEW_COMPANY_EXISTING_CLICKED =
  'Import Existing Company Clicked';

// ACCOUNT

// profile
export const EDIT_PROFILE_PAGE = 'Profile';
export const EDIT_PROFILE_CATEGORY = 'Account';
export const EDIT_PROFILE_FETCHING = 'Edit Profile Fetching';
export const EDIT_PROFILE_FETCHED = 'Edit Profile Fetched';
export const EDIT_PROFILE_SAVED = 'Edit Profile Saved';

// COMPANY REGISTRATION
export const COMPANY_REGISTRATION_CATEGORY = 'Company Registration';

// onboarding tasks page
export const ONBOARDING_TASKS_PAGE = 'Tasks';
export const ONBOARDING_TASKS_CATEGORY = 'Onboarding';
export const TASK_CLICKED = 'Task Clicked';
export const TOUR_BUTTON_CLICKED = 'Tour Button Clicked';
export const CONTACT_SUPPORT_CLICKED = 'Contact Support Clicked';
export const FAQ_BUTTON_CLICKED = 'FAQ button Clicked';

// Company Name
export const COMPANY_NAME_PAGE = 'Company Name';
export const COMPANY_NAME_FORM_SUBMITTED = 'COMPANY_NAME_FORM_SUBMITTED';
export const COMPANY_NAME_FORM_ERROR = 'COMPANY_NAME_FORM_ERROR';
export const COMPANY_NAME_SUBMITTED = 'COMPANY_NAME_SUBMITTED';
export const COMPANY_NAME_SAVED = 'COMPANY_NAME_SAVED';
export const COMPANY_NAME_INFO_CLICKED = 'COMPANY_NAME_INFO_CLICKED';

// Company Details
export const COMPANY_DETAILS_FORM_SUBMITTED = 'Company Details Form Submitted';
export const COMPANY_DETAILS_FORM_ERROR = 'Company Details Form Error';
export const COMPANY_DETAILS_SUBMITTED = 'Company Details Submitted';
export const COMPANY_DETAILS_SAVED = 'Company Details Saved';

// Add Directors
export const DIRECTOR_INFO_CLICKED = 'Director Info Clicked';
export const DUPLICATE_DIRECTOR = 'Duplicate Director';
export const DIRECTOR_ADDED = 'Director Added';
export const DIRECTORS_SAVED = 'Director Saved';

// Review Application
export const EDIT_BUTTON_CLICKED = 'Edit Button Clicked';
export const APPLICATION_SUBMITTED = 'Application Submitted';
export const APPLICATION_SAVED = 'Application Saved';
export const NAME_RESERVATION_SUBMITTED = 'Name Submitted';
export const NAME_RESERVATION_SAVED = 'Name Saved';

// Application Submitted
export const APPLICATION_SUBMITTED_PAGE = 'Application Submitted';
export const APPLICATION_SUBMITTED_CATEGORY = 'Application Submitted';

// PAYMENT
export const CHECKOUT_CATEGORY = 'Checkout';

// Checkout

export const ADD_COUPON_CLICKED = 'Add Coupon Clicked';
export const COUPON_SUBMITTED = 'Coupon Submitted';
export const COUPON_ERROR = 'Coupon Error';
export const COUPON_SAVED = 'Coupon Saved';
export const CHAT_STARTED = 'Live Chat Clicked';
export const PAYMENT_METHOD_CLICKED = 'Payment Method Clicked';
export const CHECKOUT_VIEWED = 'Checkout Viewed';
export const CHECKOUT_SUBMITTED = 'Checkout Submitted';
export const CHECKOUT_SUCCESS = 'Checkout Success';
export const CHECKOUT_INCOMPLETE = 'Checkout Incomplete';
export const BANK_ACCOUNT_SELECTED = 'Bank Account Selected';
export const BEE_SELECTED = 'BEE Selected';
export const WHATS_INCLUDED = 'Whats Included Clicked';
export const WHATS_INCLUDED_CLICKED = 'Whats Included Clicked';
export const WHATS_INCLUDED_CLOSED = 'Whats Included Closed';
export const REMOVE_ITEM_CLICKED = 'Remove Item Clicked';
export const CONFIRM_REMOVE_ITEM_CLICKED = 'Confirm Remove Item Clicked';
export const CANCEL_REMOVE_ITEM_CLICKED = 'Cancel Remove Item Clicked';
export const EDIT_APPLICATION_CLICKED = 'Edit Application Clicked';

// Snapscan
export const SNAPSCAN_PAYMENT_SUCCESS = 'Snapscan Payment Success';
export const SNAPSCAN_PAYMENT_FAILED = 'Snapscan Payment Failed';

// Direct Deposit
export const PAYMENT_SUBMITTED = 'Payment Submitted';
export const PAYMENT_SAVED = 'Payment Saved';
export const SEND_REMINDER_CLICKED = 'Send Reminder Clicked';
export const SEND_REMINDER_CLOSED = 'Send Reminder Closed';
export const REMINDER_FORM_SUBMITTED = 'Send Reminder Form Submitted';
export const REMINDER_FORM_ERROR = 'Send Reminder Form Error';
export const REMINDER_SENT = 'Reminder Sent';
export const REMINDER_ERROR = 'Reminder Error';

// Payment Success
export const PAYMENT_SUCCESS_PAGE = 'Payment Success';
export const PURCHASE = 'purchase';

// COMPANY REGISTRATION STATUS
export const COMPANY_REGISTRATION_STATUS_PAGE = 'Company Registration Status';
export const COMPANY_REGISTRATION_STATUS_CATEGORY =
  'Company Registration Status';
export const UPDATE_EMAIL_CLICKED = 'Update Email Clicked';
export const UPDATE_EMAIL_FORM_SUBMITTED = 'Update Email Form Submitted';
export const UPDATE_EMAIL_FORM_ERROR = 'Update Email Form Error';
export const UPDATE_EMAIL_FORM_SAVED = 'Update Email Form Saved';
export const UPDATE_EMAIL_SUBMITTED = 'Update Email Submitted';
export const UPDATE_EMAIL_ERROR = 'Update Email Error';
export const UPDATE_EMAIL_SAVED = 'Update Email Saved';
export const ADD_DIRECTOR_CLICKED = 'Add Director Clicked';
export const ADD_DIRECTOR_CANCEL_CLICKED = 'Add Director Cancel Clicked';
export const ADD_DIRECTOR_FORM_SUBMITTED = 'Add Director Form Submitted';
export const ADD_DIRECTOR_FORM_ERROR = 'Add Director Form Error';
export const ADD_DIRECTOR_FORM_SAVED = 'Add Director Form Saved';
export const ADD_DIRECTOR_SUBMITTED = 'Add Director Submitted';
export const ADD_DIRECTOR_DUPLICATE_DIRECTOR =
  'Add Director Duplicate Director';
export const ADD_DIRECTOR_ERROR = 'Add Director Error';
export const ADD_DIRECTOR_SAVED = 'Add Director Saved';
export const REMOVE_DIRECTOR_CLICKED = 'Remove Director Clicked';
export const REMOVE_DIRECTOR_CONFIRM_CLICKED =
  'Remove Director Confirm Clicked';
export const REMOVE_DIRECTOR_ERROR = 'Remove Director Error';
export const REMOVE_DIRECTOR_SAVED = 'Remove Director Saved';
export const REMOVE_DIRECTOR_CANCEL_CLICKED = 'Remove Director Cancel Clicked';
export const RESEND_INVITE_CLICKED = 'Resend Invite Clicked';
export const RESEND_INVITE_SAVED = 'Resend Invite Saved';
export const RESEND_INVITE_ERROR = 'Resend Invite Error';

// ANNUAL RETURNS
export const ANNUAL_RETURNS_PAGE = 'Annual Returns';
export const ANNUAL_RETURNS_CATEGORY = 'Annual Returns';

export const ANNUAL_RETURNS_FETCHING = 'Annual Returns Fetching';
export const ANNUAL_RETURNS_FETCHED = 'Annual Returns Fetched';
export const ANNUAL_RETURNS_FORM_SUBMITTED = 'Annual Returns Form Submitted';
export const TURNOVER_FORM_SUBMITTED = 'Turnover Form Submitted';
export const TURNOVER_FORM_ERROR = 'Turnover Form Error';
export const ANNUAL_RETURNS_SUBMITTED = 'Annual Returns Submitted';
export const ANNUAL_RETURNS_SAVED = 'Annual Returns Saved';

// TAX RETURNS
export const TAX_RETURNS_PAGE = 'Tax Returns';
export const TAX_RETURNS_CATEGORY = 'Tax Returns';

export const TAX_RETURNS_FETCHING = 'Tax Returns Fetching';
export const TAX_RETURNS_FETCHED = 'Tax Returns Fetched';
export const TAX_RETURNS_PERIODS_FORM_SUBMITTED =
  'Tax Returns Periods Form Submitted';
export const TAX_RETURNS_PERIODS_FORM_ERROR = 'Tax Returns Periods Form Error';
export const TAX_RETURNS_COST_SUBMITTED = 'Tax Returns Cost Submitted';
export const TAX_RETURNS_APPLICATION_FORM_SUBMITTED =
  'Tax Returns Application Form Submitted';
export const TAX_RETURNS_APPLICATION_FORM_ERROR =
  'Tax Returns Application Form Error';
export const TAX_RETURNS_SUBMITTED = 'Tax Returns Submitted';
export const TAX_RETURNS_SAVED = 'Tax Returns Saved';

// TAX CLEARANCE CERTIFICATE
export const TAX_CLEARANCE_CERTIFICATE_PAGE = 'Tax Clearance Certificate';
export const TAX_CLEARANCE_CERTIFICATE_CATEGORY = 'Tax Clearance Certificate';
export const TAX_CLEARANCE_CERTIFICATE_FORM_SUBMITTED =
  'Tax Clearance Certificate Form Submitted';
export const TAX_CLEARANCE_CERTIFICATE_FORM_ERROR =
  'Tax Clearance Certificate Form Error';
export const TAX_CLEARANCE_CERTIFICATE_SUBMITTED =
  'Tax Clearance Certificate Submitted';
export const TAX_CLEARANCE_CERTIFICATE_SAVED =
  'Tax Clearance Certificate Saved';

// VAT REGISTRATION
export const VAT_REGISTRATION_PAGE = 'VAT Registration';
export const VAT_REGISTRATION_CATEGORY = 'VAT Registration';
export const VAT_REGISTRATION_FORM_SUBMITTED =
  'VAT Registration Form Submitted';
export const VAT_REGISTRATION_FORM_ERROR = 'VAT Registration Form Error';
export const VAT_REGISTRATION_SUBMITTED = 'VAT Registration Submitted';
export const VAT_REGISTRATION_SAVED = 'VAT Registration Saved';

// IMPORT/EXPORT LICENSE
export const IMPORT_EXPORT_LICENSE_PAGE = 'Import/Export License';
export const IMPORT_EXPORT_LICENSE_CATEGORY = 'Import/Export License';
export const IMPORT_EXPORT_LICENSE_FORM_SUBMITTED =
  'Import/Export License Form Submitted';
export const IMPORT_EXPORT_LICENSE_FORM_ERROR =
  'Import/Export License Form Error';
export const IMPORT_EXPORT_LICENSE_SUBMITTED =
  'Import/Export License Submitted';
export const IMPORT_EXPORT_LICENSE_SAVED = 'Import/Export License Saved';

// PAYE REGISTRATION
export const PAYE_REGISTRATION_PAGE = 'PAYE Registration';
export const PAYE_REGISTRATION_CATEGORY = 'PAYE Registration';
export const PAYE_REGISTRATION_FORM_SUBMITTED =
  'PAYE Registration Form Submitted';
export const PAYE_REGISTRATION_FORM_ERROR = 'PAYE Registration Form Error';
export const PAYE_REGISTRATION_SUBMITTED = 'PAYE Registration Submitted';
export const PAYE_REGISTRATION_SAVED = 'PAYE Registration Saved';

// UIF REGISTRATION
export const UIF_REGISTRATION_PAGE = 'UIF Registration';
export const UIF_REGISTRATION_CATEGORY = 'UIF Registration';
export const UIF_REGISTRATION_FORM_SUBMITTED =
  'UIF Registration Form Submitted';
export const UIF_REGISTRATION_FORM_ERROR = 'UIF Registration Form Error';
export const UIF_REGISTRATION_SUBMITTED = 'UIF Registration Submitted';
export const UIF_REGISTRATION_SAVED = 'UIF Registration Saved';

// COMPANY NAME CHANGE
export const COMPANY_NAME_CHANGE_PAGE = 'Company Name Change';
export const COMPANY_NAME_CHANGE_CATEGORY = 'Company Name Change';
export const COMPANY_NAME_CHANGE_FORM_SUBMITTED =
  'Company Name Change Form Submitted';
export const COMPANY_NAME_CHANGE_FORM_ERROR = 'Company Name Change Form Error';
export const COMPANY_NAME_CHANGE_SUBMITTED = 'Company Name Change Submitted';
export const COMPANY_NAME_CHANGE_SAVED = 'Company Name Change Saved';

// COMPANY DIRECTORS CHANGE
export const COMPANY_DIRECTORS_CHANGE_PAGE = 'Company Directors Change';
export const COMPANY_DIRECTORS_CHANGE_CATEGORY = 'Company Directors Change';
export const COMPANY_DIRECTORS_CHANGE_FORM_SUBMITTED =
  'Company Directors Change Form Submitted';
export const COMPANY_DIRECTORS_CHANGE_FORM_ERROR =
  'Company Directors Change Form Error';
export const COMPANY_DIRECTORS_CHANGE_SUBMITTED =
  'Company Directors Change Submitted';
export const COMPANY_DIRECTORS_CHANGE_SAVED = 'Company Directors Change Saved';

// COMPANY ADDRESS CHANGE
export const COMPANY_ADDRESS_CHANGE_PAGE = 'Company Address Change';
export const COMPANY_ADDRESS_CHANGE_CATEGORY = 'Company Address Change';
export const COMPANY_ADDRESS_CHANGE_FORM_SUBMITTED =
  'Company Address Change Form Submitted';
export const COMPANY_ADDRESS_CHANGE_FORM_ERROR =
  'Company Address Change Form Error';
export const COMPANY_ADDRESS_CHANGE_SUBMITTED =
  'Company Address Change Submitted';
export const COMPANY_ADDRESS_CHANGE_SAVED = 'Company Address Change Saved';

// COID
export const COID_PAGE = 'COID';
export const COID_CATEGORY = 'COID';
export const COID_FORM_SUBMITTED = 'COID Form Submitted';
export const COID_FORM_ERROR = 'COID Form Error';
export const COID_SUBMITTED = 'COID Submitted';
export const COID_SAVED = 'COID Saved';

// LOGS RENEWAL
export const LOGS_RENEWAL_PAGE = 'LOGS Renewal';
export const LOGS_RENEWAL_CATEGORY = 'LOGS Renewal';
export const LOGS_RENEWAL_FETCHING = 'LOGS Renewal Fetching';
export const LOGS_RENEWAL_FETCHED = 'LOGS Renewal Fetched';
export const LOGS_RENEWAL_SAVED = 'LOGS Renewal Saved';

// CIDB
export const CIDB_PAGE = 'CIDB';
export const CIDB_CATEGORY = 'CIDB';
export const CIDB_FORM_SUBMITTED = 'CIDB Form Submitted';
export const CIDB_FORM_ERROR = 'CIDB Form Error';
export const CIDB_SUBMITTED = 'CIDB Submitted';
export const CIDB_SAVED = 'CIDB Saved';

// TRADEMARK
export const TRADEMARK_PAGE = 'Trademark';
export const TRADEMARK_CATEGORY = 'Trademark';
export const TRADEMARK_FORM_SUBMITTED = 'Trademark Form Submitted';
export const TRADEMARK_FORM_ERROR = 'Trademark Form Error';
export const TRADEMARK_SUBMITTED = 'Trademark Submitted';
export const TRADEMARK_SAVED = 'Trademark Saved';

// CONSULTATION
export const CONSULTATION_PAGE = 'Consultation';
export const CONSULTATION_CATEGORY = 'Consultation';
export const CONSULTATION_FORM_SUBMITTED = 'Consultation Form Submitted';
export const CONSULTATION_FORM_ERROR = 'Consultation Form Error';
export const CONSULTATION_SUBMITTED = 'Consultation Submitted';
export const CONSULTATION_SAVED = 'Consultation Saved';

// INVOICE GENERATOR
export const INVOICE_GENERATOR_PAGE = 'Invoice Generator';
export const INVOICE_GENERATOR_CATEGORY = 'Invoice Generator';
export const INVOICE_GENERATOR_FORM_SUBMITTED =
  'Invoice Generator Form Submitted';
export const INVOICE_GENERATOR_FORM_ERROR = 'Invoice Generator Form Error';
export const INVOICE_GENERATOR_SUBMITTED = 'Invoice Generator Submitted';
export const INVOICE_GENERATOR_SAVED = 'Invoice Generator Saved';
export const INVOICE_GENERATOR_DOWNLOADED = 'Download Invoice Clicked';
export const INVOICE_GENERATOR_RESTART = 'New Invoice Clicked';

// IMPORT COMPANY
export const IMPORT_COMPANY_PAGE = 'Import Company';
export const IMPORT_COMPANY_CATEGORY = 'Import Company';
export const IMPORT_COMPANY_SEARCH_SUBMITTED =
  'Import Company Search Submitted';
export const IMPORT_COMPANY_SEARCH_RESULTS_RETURNED =
  'Import Company Search Results Returned';
export const IMPORT_COMPANY_SEARCH_RESULTS_ERROR =
  'Import Company Search Results Error';
export const IMPORT_COMPANY_CLICKED = 'Import Company Submitted';
export const IMPORT_COMPANY_SAVED = 'Import Company Saved';
export const IMPORT_COMPANY_ERROR = 'Import Company Error';

// KYC
export const DIRECTOR_VERIFY_PAGE = 'Verify Director';
export const DIRECTOR_VERIFY_CATEGORY = 'Verify Director';

// terms
export const SIGNATURE_STARTED = 'Signature Started';
export const SIGNATURE_RESET = 'Signature Reset';
export const SIGNATURE_SUBMITTED = 'Signature Submitted';
export const SIGNATURE_SAVED = 'Signature Saved';
export const TERMS_ACCEPTED = 'Terms Accepted';
export const TERMS_SAVED = 'Terms Saved';

// documents
export const ID_TYPE_SELECTED = 'ID_TYPE_SELECTED';
export const DOCUMENT_UPLOAD_STARTED = 'DOCUMENT_UPLOAD_STARTED';
export const DOCUMENT_UPLOAD_SUCCESS = 'DOCUMENT_UPLOAD_SUCCESS';
export const DOCUMENTS_SUBMITTED = 'DOCUMENTS_SUBMITTED';
export const DOCUMENTS_ERROR = 'DOCUMENTS_ERROR';
export const DOCUMENTS_SAVED = 'DOCUMENTS_SAVED';

// director profile
export const PROFILE_FORM_SUBMITTED = 'PROFILE_FORM_SUBMITTED';
export const PROFILE_FORM_ERROR = 'PROFILE_FORM_ERROR';
export const PROFILE_SUBMITTED = 'PROFILE_SUBMITTED';
export const PROFILE_SAVED = 'PROFILE_SAVED';

// TOOLS
export const TOOLS_PAGE = 'Tools';
export const TOOLS_CATEGORY = 'Tools';

// BADGE GENERATOR
export const BADGE_GENERATOR_PAGE = 'Badge Generator';
export const BADGE_GENERATOR_CATEGORY = 'Badge Generator';
export const BADGE_CODE_COPIED = 'Badge Code Copied';
export const BADGE_LINK_COPIED = 'Badge Link Copied';

// COMPLIANCE STATUS
export const COMPLIANCE_STATUS_CATEGORY = 'Compliance Status';
export const COMPLIANCE_STATUS_VIEWED = 'Compliance Status Viewed';
export const COMPLIANCE_CATEGORIES_VIEWED = 'Compliance Categories Viewed';
export const COMPLIANCE_CATEGORIES_CLOSED = 'Compliance Categories Closed';
export const COMPLIANCE_CATEGORY_VIEWED = 'Compliance Category Viewed';
export const COMPLIANCE_CATEGORY_CLOSED = 'Compliance Category Closed';
export const COMPLIANCE_TASK_VIEWED = 'Compliance Task Viewed';
export const COMPLIANCE_TASK_CLOSED = 'Compliance Task Closed';
export const COMPLIANCE_TASK_LODGED_ELSEWHERE =
  'Compliance Task Lodged Elsewhere';
export const COMPLIANCE_TASK_STARTED = 'Compliance Task Started';

// COMPANY PROFILE GENERATOR
export const COMPANY_PROFILE_GENERATOR_CATEGORY = 'Company Profile Generator';
export const COMPANY_PROFILE_GENERATOR_PREVIEWED =
  'Company Profile Pdf Previewed';
export const COMPANY_PROFILE_GENERATOR_DOWNLOADED =
  'Company Profile Pdf Downloaded';
export const COMPANY_PROFILE_GENERATOR_SAVED = 'Company Profile Progress Saved';
export const COMPANY_PROFILE_GENERATOR_COMPLETED = 'Company Profile Completed';

// BBBEE
export const GENERATE_BBBEE_CLICKED = 'Generate B-BBEE Affidavit Clicked';
export const BBBEE_FORM_SUBMITTED = 'B-BBEE Form Submitted';
export const BBBEE_FORM_ERROR = 'B-BBEE Form Error';

// STORE
export const BUY_NOW_CLICKED = 'Buy Now Clicked';
