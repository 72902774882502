import {
  handleCompleteTask,
  handleRestoreTasks,
} from '../../modules/cipc/CompanyRegistration/utils/onboardingTaskUtils';
import { TaskType } from 'modules/cipc/CompanyRegistration/Tasks/types';

export const initialTasks: TaskType[] = [
  {
    step: 1,
    title: 'Choose Company Name',
    description: 'Provide name options for your new company',
    slug: 'company-name',
    state: 'active',
  },
  {
    step: 2,
    title: 'Enter Company Details',
    description: 'Add a company address and setup information',
    slug: 'company-details',
    state: 'locked',
  },
  {
    step: 3,
    title: 'Add Directors',
    description: 'Invite directors to your new company',
    slug: 'add-directors',
    state: 'locked',
  },
  {
    step: 4,
    title: 'Review & Submit',
    description: 'Review your application and send to our team for approval',
    slug: 'review',
    state: 'locked',
  },
];

const initialState = {
  tasks: [...initialTasks],
  directors: [],
};

export const TASKS_LOAD = 'TASKS_LOAD';
export const TASKS_REFRESH = 'TASKS_REFRESH';

export const DIRECTORS_SAVE = 'DIRECTORS_SAVE';

export const loadTasks = (tasks: TaskType) => ({
  type: TASKS_LOAD,
  payload: tasks,
});
export const refreshTasks = () => ({
  type: TASKS_REFRESH,
  payload: initialState.tasks,
});

export const saveDirectors = (directors: any) => ({
  type: DIRECTORS_SAVE,
  payload: directors,
});

export const completeTask = (step: any) => {
  return (dispatch: any, getState: any) => {
    const { onboarding } = getState();
    const { tasks } = onboarding;
    const newTasks = handleCompleteTask(tasks, step);
    dispatch(loadTasks(newTasks));
  };
};

export const restoreTasks = (company: any, directors: any) => {
  return (dispatch: any) => {
    const newTasks = handleRestoreTasks(company, directors, initialTasks);
    dispatch(loadTasks(newTasks));
  };
};

const onboarding = (state = initialState, action: any) => {
  switch (action.type) {
    case TASKS_LOAD:
      return { ...state, tasks: action.payload };
    case TASKS_REFRESH:
      return { ...state, tasks: action.payload };
    case DIRECTORS_SAVE:
      return { ...state, directors: action.payload };
    default:
      return { ...state };
  }
};

export default onboarding;
