const initialState = {
  isFetching: true,
};

export const FETCHING_TOGGLE = 'FETCHING_TOGGLE';

export const toggleFetching = (isFetching) => ({
  type: FETCHING_TOGGLE,
  payload: isFetching,
});

const ui = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_TOGGLE:
      return { ...state, isFetching: action.payload };
    default:
      return state;
  }
};

export default ui;
