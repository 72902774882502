import { PURCHASE, ACCOUNT_CREATED } from 'common/analytics/events';

// Create a gtag plugin
export const uetPlugin = (userConfig = {}) => {
  return {
    NAMESPACE: 'uetPlugin',
    config: userConfig,
    initialize: () => {},
    track: ({ payload }: any) => {
      // @ts-ignore
      const { uetq } = window;
      const { event, properties } = payload;
      if (event === ACCOUNT_CREATED) {
        uetq.push('event', 'signup', {});
      }
      if (event === PURCHASE) {
        uetq.push('event', '', {
          event_label: 'Order Completed',
          revenue_value: properties.value,
          currency: 'ZAR',
        });
      }
    },
    loaded: () => {
      return true;
    },
    ready: () => {},
  };
};

export default uetPlugin;
