import { analytics } from 'common/analytics/analytics';

export const errorHandler = (error: Error, componentStack: string) => {
  analytics.track('500 error', {
    error,
    componentStack,
  });
};

export default errorHandler;
