import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 2em;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: #6f7680;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Disclaimer = () => (
  <Wrapper>
    Govchain (Pty) Ltd (registration number 2017/257825/07) is registered in
    South Africa. Our business address is 50 Long Street, Cape Town, South
    Africa, 8000.
  </Wrapper>
);

export default Disclaimer;
