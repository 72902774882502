import axios from 'axios';

const BASEURL = process.env.REACT_APP_API_URL;

export const getCart = (token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${BASEURL}/shop/cart/self/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const makePayment = (newCompanyID, token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'patch',
      url: `${BASEURL}/newcompanys/newcompanys/${newCompanyID}/make-payment/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

// new stuffs below

export const getCurrentCart = (token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${BASEURL}/shop/carts/current/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const getCurrentOrder = (token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${BASEURL}/shop/orders/current/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const applyCoupon = (couponCode, token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${BASEURL}/shop/coupons/${couponCode}/apply/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const checkoutCart = (token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${BASEURL}/shop/carts/current/checkout/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const captureYocoPayment = (token, orderId, paymentTokenId) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${BASEURL}/payments/yoco/`,
      data: { token: paymentTokenId, order_unique_id: orderId },
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });

export const payLater = (token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'patch',
      url: `${BASEURL}/shop/orders/current/pay-later/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const checkPaymentStatus = (token, orderPk) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${BASEURL}/shop/orders/${orderPk || 'current'}/get-status/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const smsBankingDetails = (token, phoneNumber) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${BASEURL}/shop/orders/current/sms-bank-details/`,
      data: { phone: phoneNumber },
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const setPaymentSeen = (token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'patch',
      url: `${BASEURL}/shop/orders/current/paid-seen/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const removeCartItem = (token, pk) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'patch',
      url: `${BASEURL}/shop/carts/current/remove-item/`,
      data: { item_pk: pk },
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });
