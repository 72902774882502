import qs from 'qs';
import { CompanyType, CompanyStateType } from 'common/types/CompanyType';
import { Order } from 'modules/shop/Pay/types';

export const companyUrls = {
  onboarding: '/onboarding',
  submitted: '/payment/checkout',
  verifying_directors: '/onboarding/status',
  processing: '/onboarding/status',
  default: '/dashboard',
  blank: '/companys',
};

export const handleCompanyRedirectUrl = (
  companyState: CompanyStateType | '' = ''
) => {
  if (companyState === '') {
    return companyUrls.blank;
  }
  if (
    companyState === 'onboarding' ||
    companyState === 'submitted' ||
    companyState === 'verifying_directors' ||
    companyState === 'processing'
  ) {
    return companyUrls[companyState];
  }

  return companyUrls.default;
};

type handleAuthRedirectUrlTypes = {
  companyState: CompanyStateType | '';
  orderState: string;
  query?: string;
  companies: CompanyType[];
  order?: Order;
};

export const handleAuthRedirectUrl = ({
  companyState,
  orderState = '',
  query = '',
  companies = [],
  order,
}: handleAuthRedirectUrlTypes) => {
  if (companies && companies.length === 0) {
    return '/companys';
  }
  let hasOrderPlan = false;

  if (order?.items?.length) {
    hasOrderPlan = order.items.some((item) => item.title.includes('Plan'));
  }

  if (orderState === 'paid' && !hasOrderPlan) {
    return '/payment/success';
  }

  if (!companyState) {
    return '/companys';
  }

  const values = qs.parse(query, { ignoreQueryPrefix: true });
  const { redirect } = values;
  if (redirect) {
    return redirect;
  }

  return handleCompanyRedirectUrl(companyState);
};
