// @ts-ignore
import {
  ACCOUNT_CREATED,
  CHECKOUT_VIEWED,
  PURCHASE,
} from 'common/analytics/events';

const CONVERSIONS = {
  accountCreated: {
    event: ACCOUNT_CREATED,
    facebookEventName: 'CompleteRegistration',
  },
  checkoutViewed: {
    event: CHECKOUT_VIEWED,
    facebookEventName: 'InitiateCheckout',
  },
  orderCompleted: {
    event: PURCHASE,
    facebookEventName: 'Purchase',
  },
};

let fb: any;
let fbLoaded = false;

export const facebookPixelPlugin = (userConfig = {}) => {
  return {
    NAMESPACE: 'facebookPixelPlugin',
    config: userConfig,
    // @ts-ignore
    initialize: async ({ config }) => {
      const { pixelId } = config;
      await import('react-facebook-pixel')
        // eslint-disable-next-line
        .then((module) => (fb = module.default))

        .then(() => {
          if (!fbLoaded) {
            fb.init(pixelId, {
              autoConfig: true,
              debug: true,
            });
            fbLoaded = true;
          }
        });
    },
    page: () => {
      fb.pageView();
    },
    track: ({ payload }: any) => {
      const { accountCreated, checkoutViewed, orderCompleted } = CONVERSIONS;
      const { event, properties } = payload;
      if (event === accountCreated.event) {
        fb.track(accountCreated.facebookEventName);
      }
      if (event === checkoutViewed.event) {
        fb.track(checkoutViewed.facebookEventName);
      }
      if (event === orderCompleted.event) {
        fb.track(orderCompleted.facebookEventName, { ...properties });
      }
    },
    loaded: () => {
      return fbLoaded;
    },
  };
};

export default facebookPixelPlugin;
