import axios from 'axios';

import { deleteCookie, clearCookie } from '../utils/cookiesUtils';
import { analytics } from 'common/analytics/analytics';
import { LOGOUT_CLICKED } from 'common/analytics/events';

export const BASEURL = process.env.REACT_APP_API_URL;

/* eslint-disable prefer-promise-reject-errors */

export const createUser = (payload) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${BASEURL}/accounts/users/`,
      data: payload,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });

export const getUser = (token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${BASEURL}/accounts/users/${token}/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject('Error fetching and parsing data', error);
      });
  });

// logout user

export const clearUserData = () => {
  clearCookie();
  deleteCookie();
  window.localStorage.clear();
};

export const logoutUser = () => {
  analytics.track(LOGOUT_CLICKED);
  clearUserData();
  window.location.href = '/login';
  analytics.reset();
  analytics.ready(() => {
    analytics.plugins.intercom.shutdown();
  });
};

export const resendLogin = (email) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${BASEURL}/accounts/users/resend/`,
      data: {
        email,
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject('Error fetching and parsing data', error);
      });
  });

export const updateUser = (data, token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'put',
      url: `${BASEURL}/accounts/users/me/`,
      headers: { Authorization: `Token ${token}` },
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject('Error fetching and parsing data', error);
      });
  });
