import humps from 'humps';

// eslint-disable-next-line import/no-cycle
import { fetchCurrentCompany, fetchCompanies } from './companies';
import { fetchCurrentOrder } from './shop';
import { toggleFetching } from './ui';
import { addNewCompany } from '../lib/companyServices';
import { createUser, getUser, clearUserData } from '../lib/user';
import { setToken } from '../utils/cookiesUtils';
// eslint-disable-next-line import/no-cycle

export const initialState = {
  token: '',
  uuid: '',
  email: '',
  firstName: '',
  lastName: '',
  currentCompanyId: null,
};

export const USER_LOAD = 'USER_LOAD';

type TokenType = string;

// type UserProfileType = {
//   birthDate: string;
//   firstName: string;
//   gclid: string;
//   identityNumber: string;
//   lastName: string;
//   originCountry: string;
//   phoneNumber: string;
//   physicalBuilding: string;
//   physicalCity: string;
//   physicalPostCode: string;
//   physicalProvince: string;
//   physicalStreet: string;
//   postalBuilding: string;
//   postalCity: string;
//   postalPostCode: string;
//   postalProvince: string;
//   postalStreet: string;
// };

// type UserType = {
//   token: TokenType;
//   currentCompanyId: number | null;
//   email: string;
//   firstName: string;
//   lastName: string;
//   uuid: string;
//   profile?: UserProfileType;
// };

type UserType = any;
type DispatchType = Function;
type CookiesType = any;
type ActionType = any;

export const loadUser = (user: UserType) => ({
  type: USER_LOAD,
  payload: user,
});

export const fetchUser = (token: TokenType) => {
  return async (dispatch: DispatchType) => {
    try {
      const user: UserType = await getUser(token);
      const formattedUser = humps.camelizeKeys(user);
      dispatch(loadUser({ ...formattedUser, token }));
      return formattedUser;
    } catch (error) {
      return Error(`Error fetching and parsing data ${error}`);
    }
  };
};

export const fetchUserAndCompanies = (token: TokenType) => {
  return async (dispatch: DispatchType) => {
    try {
      dispatch(toggleFetching(true));
      const user = await dispatch(fetchUser(token));
      const { currentCompanyId } = user;
      const companies = await dispatch(fetchCompanies(token));
      let currentCompany = {};
      let currentOrder = {};
      if (companies.length > 0 && currentCompanyId) {
        currentCompany = await dispatch(
          fetchCurrentCompany(currentCompanyId, token)
        );
        currentOrder = await dispatch(fetchCurrentOrder(token));
      }
      dispatch(toggleFetching(false));
      const response = [user, currentCompany, companies, currentOrder];
      return response;
    } catch (error) {
      return Error(`Error fetching and parsing data ${error}`);
    }
  };
};

export const createNewUser = (user: UserType, cookies: CookiesType) => {
  return async (dispatch: DispatchType) => {
    const response = await createUser(user);
    if (response === 'username exists') {
      return 'username exists';
    }
    clearUserData();
    const { token } = response;
    setToken(cookies, token);
    const formattedUser = humps.camelizeKeys(response);
    dispatch(loadUser({ ...formattedUser, phoneNumber: user.phoneNumber }));
    return formattedUser;
  };
};

export const createNewUserAndCompany = (
  user: UserType,
  cookies: CookiesType
) => {
  return async (dispatch: DispatchType) => {
    const response = await createUser(user);
    if (response === 'username exists') {
      return 'username exists';
    }
    clearUserData();
    const { token } = response;
    setToken(cookies, token);
    const formattedUser = humps.camelizeKeys(response);
    const company = await addNewCompany(token);
    dispatch(
      loadUser({
        ...formattedUser,
        phoneNumber: user.phoneNumber,
        currentCompanyId: company.pk,
      })
    );
    return formattedUser;
  };
};

const user = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case USER_LOAD:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default user;
