import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LoadingPage from 'common/components/LoadingPage';
import { handleAuthRedirectUrl } from 'common/utils/authUtils';

export class Callback extends Component {
  componentDidMount() {
    const { companyState } = this.props;
    this.handleRedirect(companyState);
  }

  handleRedirect = () => {
    const { companyState, orderState, companies, history, order } = this.props;
    history.push(
      handleAuthRedirectUrl({ companyState, orderState, companies, order })
    );
  };

  render() {
    return <LoadingPage />;
  }
}

Callback.propTypes = {
  companyState: PropTypes.string.isRequired,
  companies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  order: PropTypes.shape({}).isRequired,
  orderState: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

Callback.defaultProps = {
  orderState: '',
};

const connectedCallback = connect((state) => {
  return {
    companyState: state.companies.currentCompany.state,
    companies: state.companies.companies,
    orderState: state.shop.order.state,
    order: state.shop.order,
  };
})(Callback);

export default withRouter(connectedCallback);
