export const handleCompleteTask = (tasks, step) => {
  let newTasks = tasks;
  const currentTaskIndex = tasks.findIndex((t) => t.step === step);
  newTasks = newTasks.map((task, index) => {
    if (index === currentTaskIndex) {
      return { ...task, state: 'complete' };
    }
    return task;
  });
  const nextTaskIndex = currentTaskIndex + 1;
  newTasks = newTasks.map((task, index) => {
    if (index === nextTaskIndex && task.state === 'locked') {
      return { ...task, state: 'active' };
    }
    return task;
  });
  return newTasks;
};

export const completeAllTasks = (tasks) => {
  const completedTasks = tasks.map((task) => {
    return { ...task, state: 'complete' };
  });
  return completedTasks;
};

export const handleRestoreTasks = (company, directors, tasks) => {
  const { state, namePrimary, physicalStreet } = company;
  if (state === 'new') {
    let newTasks = tasks;
    if (namePrimary) {
      newTasks = handleCompleteTask(newTasks, 1);
    }
    if (physicalStreet) {
      newTasks = handleCompleteTask(newTasks, 2);
    }
    if (directors.length > 0) {
      newTasks = handleCompleteTask(newTasks, 3);
    }
    return newTasks;
  }
  return completeAllTasks(tasks);
};
