import styled from 'styled-components';

const PROMOURL = process.env.REACT_APP_PROMO_URL;

const Wrapper = styled.ul`
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 0;
  text-align: right;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
  }
`;

const MenuItem = styled.li`
  display: inline-block;
  padding-left: 7px;
  padding-right: 7px;
  a {
    color: #22262e;
    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

const FooterMenu = () => {
  return (
    <Wrapper>
      <MenuItem>
        <a target="blank" href="https://help.govchain.co.za/">
          Support
        </a>
      </MenuItem>
      <MenuItem>
        <a href={`${PROMOURL}legal`}>Legal</a>
      </MenuItem>
      <MenuItem>
        <a href={`${PROMOURL}contact`}>Contact</a>
      </MenuItem>
    </Wrapper>
  );
};

export default FooterMenu;
