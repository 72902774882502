import humps from 'humps';
import {
  getCurrentCart,
  checkoutCart,
  applyCoupon,
  getCurrentOrder,
} from '../lib/shopServices';

export const initialState = {
  cart: {
    coupon: null,
    datePlaced: '',
    description: '',
    ipayUrlEft: '',
    items: [],
    payfastUrlCc: '',
    payfastUrlEft: '',
    paymentMethod: '',
    snapscanQrUrl: '',
    snapscanUrl: '',
    state: '',
    totalPrice: null,
    uniqueId: '',
  },
  order: {
    coupon: null,
    datePlaced: '',
    items: [],
    paymentMethod: '',
    state: '',
    totalPrice: null,
    uniqueId: '',
  },
};

export const CART_LOAD = 'CART_LOAD';
export const ORDER_LOAD = 'ORDER_LOAD';

export const loadCart = (cart) => ({ type: CART_LOAD, payload: cart });
export const loadOrder = (order) => ({ type: ORDER_LOAD, payload: order });

export const handleNameReservationWithIncorporation = (cart) => {
  const nameReservationTitle = 'Name Reservation';
  const incorporationTitle = 'New Company Registration Package';
  const hasNameReservation = cart.items.find((item) =>
    item.title.includes(nameReservationTitle)
  );
  const hasIncorporation = cart.items.find((item) =>
    item.title.includes(incorporationTitle)
  );
  if (hasNameReservation && hasIncorporation) {
    const newOrderItems = cart.items.filter(
      (item) => item.title !== nameReservationTitle
    );
    const newOrder = { ...cart, items: newOrderItems };
    return newOrder;
  }
  return cart;
};

export const fetchCurrentCart = (token) => {
  return async (dispatch) => {
    try {
      const cart = await getCurrentCart(token);
      const formattedCart = humps.camelizeKeys(cart);
      const filteredCart =
        handleNameReservationWithIncorporation(formattedCart);
      await dispatch(loadCart(filteredCart));
      return filteredCart;
    } catch (error) {
      return Error('Error fetching and parsing data', error);
    }
  };
};

export const fetchCurrentOrder = (token) => {
  return async (dispatch) => {
    try {
      const order = await getCurrentOrder(token);
      const formattedOrder = humps.camelizeKeys(order);
      await dispatch(loadOrder(formattedOrder));
      return formattedOrder;
    } catch (error) {
      return Error('Error fetching and parsing data', error);
    }
  };
};

export const setCouponCode = (token, couponCode) => {
  return async (dispatch) => {
    try {
      // apply coupon code
      await applyCoupon(couponCode, token);
      const cart = await getCurrentCart(token);
      const formattedCart = humps.camelizeKeys(cart);
      await dispatch(loadCart(formattedCart));
      return formattedCart;
    } catch (error) {
      Error('Error fetching and parsing data', error);
      return false;
    }
  };
};

export const checkoutCurrentCart = (token) => {
  return async (dispatch) => {
    try {
      const cart = await checkoutCart(token);
      const formattedCart = humps.camelizeKeys(cart);
      await dispatch(loadCart(formattedCart));
      return formattedCart;
    } catch (error) {
      return Error('Error fetching and parsing data', error);
    }
  };
};

const shop = (state = initialState, action) => {
  switch (action.type) {
    case CART_LOAD:
      return { ...state, cart: { ...action.payload } };
    case ORDER_LOAD:
      return { ...state, order: { ...action.payload } };
    default:
      return state;
  }
};

export default shop;
