import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

/* eslint-disable no-else-return */

type PrivateRouteProps = {
  component: React.ComponentType<any>;
  token?: string;
  location?: any;
  companyState?: any;
  path: string;
  exact?: boolean;
};

const PrivateRoute = ({
  component: Component,
  token,
  exact = false,
  path,
  companyState,
  ...routeProps
}: PrivateRouteProps) => {
  const stateToken = useSelector((state: any) => state.user.token);

  const routeToken = token || stateToken;
  return (
    <Route
      exact={exact}
      path={path}
      {...routeProps}
      render={(componentProps) => {
        if (!routeToken) {
          return <Redirect to="/login" key="from-hello" />;
        } else {
          return <Component {...componentProps} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
