export const isValidMobilePrefix = (mobileNumber: string) => {
  let response = false;
  // link for info: https://en.wikipedia.org/wiki/Telephone_numbers_in_South_Africa#Cellular
  // must start with 06,07,08
  if (/^(0)[6-8]/.test(mobileNumber)) {
    response = true;
  }
  // must not start with 085,086,087,088,089
  if (/^(0)[8][5-9]/.test(mobileNumber)) {
    response = false;
  }
  return response;
};
