import { PhoneOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';

import { isValidMobilePrefix } from 'common/utils/validateMobileNumber';

const FormItem = Form.Item;

export const validateMobileNumber = (rule: any, value: string) => {
  if (!rule.required && !value) {
    return Promise.resolve();
  }
  if (!isValidMobilePrefix(value)) {
    return Promise.reject(
      new Error('You must enter a valid RSA mobile number')
    );
  }
  return Promise.resolve();
};

export const normalizeMobileNumber = (value: string) => {
  return value && value.replace(/\s|\D/g, '').trim();
};

export type MobileNumberFieldProps = {
  id?: string;
  label?: string;
  size?: 'large' | 'middle' | 'small';
  required?: boolean;
  extra?: string;
  showIcon?: boolean;
  placeholder?: string;
};

export const MobileNumberField = ({
  id = 'phoneNumber',
  label = 'Mobile Number',
  size = 'large',
  required = true,
  showIcon = true,
  extra = '',
  placeholder = '',
  ...props
}: MobileNumberFieldProps) => (
  <>
    <FormItem
      name={id}
      label={label}
      validateFirst
      rules={[
        {
          required,
          message: 'Required',
        },
        {
          validator: validateMobileNumber,
        },
        {
          message: 'Mobile number must be 10 digits e.g. 0825650909',
          len: 10,
        },
      ]}
      normalize={(value) => normalizeMobileNumber(value)}
      extra={extra}
      {...props}
    >
      <Input
        size={size}
        autoComplete="tel"
        prefix={
          showIcon && <PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
        }
        placeholder={placeholder}
      />
    </FormItem>
  </>
);

export default MobileNumberField;
