import humps from 'humps';

import { restoreTasks } from './onboarding';
// eslint-disable-next-line import/no-cycle
import { fetchUserAndCompanies } from './user';
import {
  getCurrentCompany,
  getCompanies,
  setCurrentCompany,
} from '../lib/companyServices';

export const initialState = {
  companies: [],
  currentCompany: {
    authorizedShares: null,
    enterpriseNum: '',
    financialYearEnd: '',
    name: '',
    nameBackup1: '',
    nameBackup2: '',
    nameBackup3: '',
    namePrimary: '',
    businessDescription: '',
    physicalStreet: '',
    physicalBuilding: '',
    physicalCity: '',
    physicalProvince: '',
    physicalPostCode: '',
    postalStreet: '',
    postalBuilding: '',
    postalCity: '',
    postalProvince: '',
    postalPostCode: '',
    registrationNum: '',
    reservedName: '',
    taxReferenceNum: '',
  },
};

export const COMPANY_LOAD = 'COMPANY_LOAD';
export const COMPANIES_LOAD = 'COMPANIES_LOAD';

export const loadCompany = (company) => ({
  type: COMPANY_LOAD,
  payload: company,
});
export const loadCompanies = (companies) => ({
  type: COMPANIES_LOAD,
  payload: companies,
});

/* eslint-disable arrow-body-style */

export const fetchCurrentCompany = (currentCompanyId, token) => {
  return async (dispatch) => {
    try {
      const company = await getCurrentCompany(currentCompanyId, token);
      const formattedCompany = humps.camelizeKeys(company);
      await dispatch(loadCompany(formattedCompany));
      return company;
    } catch (error) {
      return Error('Error fetching and parsing data', error);
    }
  };
};

export const fetchCompanies = (token) => {
  return async (dispatch) => {
    try {
      const companies = await getCompanies(token);
      dispatch(loadCompanies(companies));
      return companies;
    } catch (error) {
      return Error('Error fetching and parsing data', error);
    }
  };
};

export const setUserCurrentCompanyById = (companyId, token) => {
  return async (dispatch, getState) => {
    const { directors } = getState();
    try {
      // set current company using api
      await setCurrentCompany(companyId, token);
      // get the new user and companies from api and set to state
      const account = await dispatch(fetchUserAndCompanies(token));
      // restore tasks of current company
      const currentCompany = account[1];
      const formattedCompany = humps.camelizeKeys(currentCompany);
      await dispatch(restoreTasks(formattedCompany, directors.list));
      // return the current company
      return formattedCompany;
    } catch (error) {
      return Error('Error fetching and parsing data', error);
    }
  };
};

const companies = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_LOAD:
      return { ...state, currentCompany: { ...action.payload } };
    case COMPANIES_LOAD:
      return { ...state, companies: action.payload };
    default:
      return state;
  }
};

export default companies;
