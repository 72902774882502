// @ts-ignore
// import gtag, { install } from 'ga-gtag';
import {
  ACCOUNT_CREATED,
  PURCHASE,
  IMPORT_COMPANY_SAVED,
} from 'common/analytics/events';

// Define conversion configurations
const CONVERSIONS = {
  accountCreated: {
    event: ACCOUNT_CREATED,
    conversionLabel: 'AW-812847945/uS58CPvo7H4QyabMgwM',
  },
  purchase: {
    event: PURCHASE,
    conversionLabel: 'AW-812847945/T3wUCNXXt5oBEMmmzIMD',
  },
  importCompanySaved: {
    event: IMPORT_COMPANY_SAVED,
    conversionLabel: 'AW-812847945/QoPCKXU1c0BEMmmzIMD',
  },
};

// Create a gtag plugin
export const gtagPlugin = (userConfig = {}) => {
  return {
    NAMESPACE: 'gtagPlugin',
    config: userConfig,
    initialize: () => {
      // @ts-ignore
      const { conversionId } = userConfig;
      // @ts-ignore
      const { gtag } = window;
      // install(trackingId);
      gtag('config', conversionId);
    },
    track: ({ payload }: any) => {
      // @ts-ignore
      const { gtag } = window;
      const { accountCreated, purchase, importCompanySaved } = CONVERSIONS;
      const { event, properties } = payload;
      if (event === accountCreated.event) {
        gtag('event', accountCreated.event, {
          send_to: accountCreated.conversionLabel,
        });
      }
      if (event === purchase.event) {
        gtag('event', purchase.event, {
          send_to: purchase.conversionLabel,
          ...properties,
        });
      }
      if (event === importCompanySaved.event) {
        gtag('event', importCompanySaved.event, {
          send_to: importCompanySaved.conversionLabel,
        });
      }
    },
    loaded: () => {
      return true;
    },
    ready: () => {},
  };
};

export default gtagPlugin;
