import React from 'react';
import { ConfigProvider } from 'antd';

const validateMessages = {
  required: 'This field is required',
};

type AntDesignProviderProps = {
  children: React.ReactNode;
};

export const AntDesignProvider = ({ children }: AntDesignProviderProps) => {
  return (
    <ConfigProvider form={{ validateMessages }}>{children}</ConfigProvider>
  );
};

export default AntDesignProvider;
