import qs from 'qs';
import { getDomain } from 'tldjs';

/**
 * Determines if a given link is an external link.
 *
 * A link is considered external if:
 * - It has a domain.
 * - The domain is not 'govchain.co.za'.
 *
 * @param link - The URL or link string to check.
 * @returns `true` if the link is external, otherwise `false`.
 */
export const isExternalLink = (link: string): boolean => {
  const domain = getDomain(link);
  return domain !== null && domain !== 'govchain.co.za';
};

/**
 * Opens a given link in a new browser tab or window.
 *
 * @param link - The URL or link to open.
 */
export const openLinkInNewTab = (link: string): void => {
  const win = window.open(link, '_blank');

  // Ensure the new page cannot access the window.opener property
  if (win) {
    win.opener = null;
    win.focus();
  }
};

/**
 * Ensures that the provided storage URL is a full and valid S3 URL.
 * If the input storageUrl is already a full URL (starting with "https://"),
 * it returns the URL unchanged. Otherwise, it constructs the full S3 URL
 * using the provided bucket name and the given relative path.
 *
 * @param storageUrl - The input storage URL, which can be a full URL or a relative path.
 * @param bucketName - The name of the S3 bucket to be used when constructing the full URL.
 * @returns A full and valid S3 URL.
 */
export const ensureFullS3Url = (
  storageUrl: string,
  bucketName: string
): string =>
  storageUrl.startsWith('https://')
    ? storageUrl
    : `https://s3.eu-west-1.amazonaws.com/${bucketName}/${storageUrl}`;

/**
 * Extracts and returns the filename from a given URL or path.
 *
 * For example, given "https://example.com/path/to/file.txt", it returns "file.txt".
 * If provided with just "file.txt", it will return "file.txt".
 *
 * @param urlOrPath - The input URL or path string.
 * @returns The extracted filename.
 */

export const filenameFromUrl = (urlOrPath: string): string => {
  const parts = urlOrPath.split('/');
  return parts[parts.length - 1];
};

/**
 * Adds query parameters to a given URL.
 *
 * This function takes a base URL and an object of query parameters,
 * serializes the query parameters using the qs library, and appends
 * them to the URL.
 *
 * @param url - The base URL to which query parameters will be added.
 * @param queryParams - An object containing key-value pairs of query parameters.
 * @returns The URL with the appended query parameters.
 */
export const addQueryParamsToUrl = (
  url: string,
  queryParams: Record<string, any>
): string => {
  // Serialize the query parameters into a query string
  const queryString = qs.stringify(queryParams, { arrayFormat: 'brackets' });

  // Check if the URL already has query parameters
  const separator = url.includes('?') ? '&' : '?';

  // Return the URL with the appended query parameters
  return `${url}${separator}${queryString}`;
};
