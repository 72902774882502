import { SignupFormValues, CreateUserApiPayload } from '../types';
import { trimAllObjectValues } from 'common/utils/objectUtils';

export const createUserApiPayload = (
  formValues: SignupFormValues,
  gclid: string
): CreateUserApiPayload => {
  const trimmedFormValues = trimAllObjectValues(formValues);
  const { email, firstName, lastName, phoneNumber } = trimmedFormValues;
  const payload = {
    email,
    firstName,
    lastName,
    profile: {
      phoneNumber,
      gclid: gclid ? decodeURIComponent(gclid) : '',
    },
  };

  return payload;
};
