import { HomeOutlined } from '@ant-design/icons';

import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-size: 5em;
  font-weight: 500;
`;

const Description = styled.div`
  font-size: 2em;
  margin-bottom: 1em;
`;

const NotFound = () => {
  const history = useHistory();

  const companies = useSelector((state: any) => state.companies.companies);

  const handleBack = () => {
    if (!companies?.length) return history.push('/companys');
    return history.push('/dashboard');
  };

  return (
    <Wrapper>
      <Title>Oops</Title>
      <Description>The page you requested does not exist.</Description>
      <Button
        type="primary"
        size="large"
        icon={<HomeOutlined />}
        onClick={handleBack}
      >
        Back to home
      </Button>
    </Wrapper>
  );
};

export default withRouter(NotFound);
