import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import throttle from 'lodash/throttle';

import { companyProfileApi } from 'common/lib/companyProfileService';
import createRootReducer from 'common/reducers/reducers';
import { loadState, saveState } from 'common/utils/localStorage';

export const history = createBrowserHistory();

const rootReducer = createRootReducer(history);

const persistedState = loadState();

const configureAppStore = (preloadedState?: any) => {
  const store = configureStore({
    reducer: rootReducer,
    // @ts-ignore
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        routerMiddleware(history),
        companyProfileApi.middleware
      ),
    preloadedState,
  });

  store.subscribe(
    throttle(() => {
      saveState({
        onboarding: store.getState().onboarding,
      });
    }, 1000)
  );

  return store;
};

export const configuredAppStore = configureAppStore(persistedState);

export default configuredAppStore;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof configuredAppStore.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
