import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';

import 'common/utils/polyfills';
import 'theme/index.css';
import 'theme/index.less';
import App from './app/App';
import { unregister } from 'common/utils/registerServiceWorker';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

unregister();
