import qs from 'qs';
import { CompanyType } from '../types';

export const getCompanyTypeBySearchQuery = (location: any): CompanyType => {
  const searchQuery = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  if (
    searchQuery.type === 'new' ||
    searchQuery.type === 'existing' ||
    searchQuery.type === ''
  ) {
    return searchQuery.type;
  }
  return '';
};
