import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .ant-spin-dot {
    margin-bottom: 16px;
  }
`;

export type LoadingPageProps = {
  label?: string;
};

const LoadingPage = ({ label = 'Loading...' }: LoadingPageProps) => (
  <Wrapper>
    <Spin
      size="large"
      indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
    />
    {label && <div>{label}</div>}
  </Wrapper>
);

export default LoadingPage;
