import React, { Suspense, lazy } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useSelector } from 'react-redux';
import { Switch, Route, useLocation } from 'react-router-dom';
// @ts-ignore
import { useAnalytics } from 'use-analytics';

import { ErrorFallback, errorHandler } from 'common/components/ErrorFallback';

import LoadingPage from 'common/components/LoadingPage';
import NotFound from 'common/components/NotFound/index';
import PrivateRoute from 'common/components/PrivateRoute';

// NB ROUTES THAT MOST LOAD WITH MAIN BUNDLE
import Callback from 'modules/auth/Callback';
import InviteLogin from 'modules/auth/InviteLogin';
import Login from 'modules/auth/Login';
import Signup from 'modules/auth/Signup';
import TokenLogin from 'modules/auth/TokenLogin';
import Maintenance from 'modules/maintenance';

// LAZY LOADED ROUTES
const Accounting = lazy(() => import('modules/accounting'));
const Addons = lazy(() => import('modules/shop/Addons'));
const AddressChange = lazy(() => import('modules/cipc/AddressChange'));
const AnnualReturns = lazy(() => import('modules/cipc/AnnualReturns'));
const BankAccount = lazy(() => import('modules/banking/BankAccount'));
const BadgeGenerator = lazy(() => import('modules/tools/BadgeGenerator'));
const BBBEE = lazy(() => import('modules/bbbee/BbbeeCertificate'));
const BeneficialOwnership = lazy(
  () => import('modules/cipc/BeneficialOwnership')
);
const Cidb = lazy(() => import('modules/cidb/CidbRegistration'));
const Coid = lazy(() => import('modules/labour/CoidRegistration'));
const Companies = lazy(() => import('modules/companies/Companies'));
const CompanyProfile = lazy(
  () => import('modules/tools/CompanyProfile/CompanyProfile')
);
const CompanyRegistration = lazy(
  () => import('modules/cipc/CompanyRegistration/CompanyRegistration')
);
const Dashboard = lazy(() => import('modules/company/Dashboard'));
const Deregistration = lazy(() => import('modules/cipc/Deregistration'));
const DirectorsChange = lazy(() => import('modules/cipc/DirectorsChange'));
const Documents = lazy(() => import('modules/company/Documents'));
const ImportExportLicense = lazy(
  () => import('modules/sars/ImportExportLicense')
);
const IncorporationDocuments = lazy(
  () => import('modules/cipc/IncorporationDocuments')
);
const InvoiceGenerator = lazy(() => import('modules/tools/InvoiceGenerator'));
const LogsRenewal = lazy(() => import('modules/labour/LogsRenewal'));
const NameChange = lazy(() => import('modules/cipc/NameChange'));
const OrdersProvider = lazy(
  () => import('modules/account/Orders/OrdersProvider')
);
const Payment = lazy(() => import('modules/shop/Pay/Payment'));
const PayeRegistration = lazy(() => import('modules/sars/PayeRegistration'));
const Profile = lazy(() => import('modules/company/Profile'));
const Refund = lazy(() => import('modules/shop/Refund'));
const RegisteredRep = lazy(() => import('modules/sars/RegisteredRep'));
const Reinstatement = lazy(() => import('modules/cipc/Reinstatement'));
const Services = lazy(() => import('modules/shop/Services/Services'));
const Shares = lazy(() => import('modules/shares/ShareCertificates'));
const TaxClearance = lazy(() => import('modules/sars/TaxClearance'));
const TaxReturns = lazy(() => import('modules/sars/TaxReturns'));
const TaxReturnsStatus = lazy(
  () => import('modules/sars/TaxReturns/Status/TaxReturnsStatusProvider')
);
const Tools = lazy(() => import('modules/tools'));
const Trademark = lazy(() => import('modules/cipc/Trademark'));
const UifRegistration = lazy(() => import('modules/labour/UifRegistration'));
const VATRegistration = lazy(() => import('modules/sars/VATRegistration'));
const VatReturns = lazy(() => import('modules/sars/VatReturns'));
const VerifyDirector = lazy(
  () => import('modules/company/Directors/VerifyDirector')
);
const UserProfile = lazy(() => import('modules/account/Profile'));
const CsdRegistration = lazy(() => import('modules/csd/CsdRegistration'));
const Subscriptions = lazy(() => import('modules/subcriptions/Subscriptions'));

// init sentry error tracking
Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const Routes = () => {
  const location = useLocation();
  const analytics = useAnalytics();

  React.useEffect(() => {
    // send page view on route change
    analytics.page();
  }, [analytics, location]);

  const token = useSelector((state: any) => state.user.token);
  const companyState = useSelector(
    (state: any) => state.companies.currentCompany.state
  );
  const authProps = { token, companyState };
  return (
    <main>
      <Sentry.ErrorBoundary fallback={ErrorFallback} onError={errorHandler}>
        <Suspense fallback={<LoadingPage />}>
          <Switch>
            {/* MAINTENANCE */}
            <Route exact path="/maintenance" component={Maintenance} />

            {/* AUTH */}

            <Route exact path="/" component={Callback} />
            <Route path="/signup" component={Signup} />
            <Route exact path="/login" component={Login} />
            <Route path="/login/:token" render={() => <TokenLogin />} />
            <Route path="/invite/:inviteId" render={() => <InviteLogin />} />

            {/* COMPANY REGISTRATIONS */}

            <PrivateRoute
              path="/onboarding"
              component={CompanyRegistration}
              {...authProps}
            />

            {/* DIRECTORS */}

            <PrivateRoute
              path="/director/verify/:inviteID"
              component={VerifyDirector}
              {...authProps}
            />
            {/* PAYMENT */}

            <PrivateRoute path="/payment" component={Payment} {...authProps} />

            {/* ADDONS */}
            <PrivateRoute
              path="/shop/addons"
              component={Addons}
              {...authProps}
            />

            {/* SUBSCRIPTIONS */}
            <PrivateRoute path="/subscriptions" component={Subscriptions} />

            {/* DASHBOARD */}

            <PrivateRoute
              path="/dashboard"
              component={Dashboard}
              {...authProps}
            />
            <PrivateRoute
              path="/documents"
              component={Documents}
              {...authProps}
            />
            <PrivateRoute path="/profile" component={Profile} {...authProps} />

            <PrivateRoute path="/bbbee" component={BBBEE} {...authProps} />

            {/* STORE */}

            <PrivateRoute path="/store" component={Services} {...authProps} />

            {/* COMPANIES */}
            <PrivateRoute
              path="/companys"
              component={Companies}
              {...authProps}
            />

            {/* TOOLS */}

            <PrivateRoute
              exact
              path="/tools"
              component={Tools}
              {...authProps}
            />

            <PrivateRoute
              path="/tools/invoice-maker"
              component={InvoiceGenerator}
              {...authProps}
            />

            <PrivateRoute
              path="/tools/badge-maker"
              component={BadgeGenerator}
              {...authProps}
            />

            <PrivateRoute
              path="/tools/company-profile"
              component={CompanyProfile}
              {...authProps}
            />

            {/* ACCOUNT */}
            <PrivateRoute
              path="/account/billing"
              component={OrdersProvider}
              {...authProps}
            />
            <PrivateRoute
              path="/account/profile"
              component={UserProfile}
              {...authProps}
            />

            {/* SERVICES */}
            <PrivateRoute
              path="/tax-clearance-certificate"
              component={TaxClearance}
              {...authProps}
            />
            <PrivateRoute
              path="/tax-returns"
              component={TaxReturns}
              {...authProps}
            />
            <PrivateRoute
              path="/tax-returns-status"
              component={TaxReturnsStatus}
              {...authProps}
            />
            <PrivateRoute
              path="/accounting/tax-returns-status"
              component={TaxReturnsStatus}
              {...authProps}
            />
            <PrivateRoute
              path="/vat-returns"
              component={VatReturns}
              {...authProps}
            />
            <PrivateRoute
              path="/accounting/vat-returns"
              component={VatReturns}
              {...authProps}
            />
            <PrivateRoute
              path="/bank-account"
              component={BankAccount}
              {...authProps}
            />

            <PrivateRoute
              path="/annual-returns"
              component={AnnualReturns}
              {...authProps}
            />

            <PrivateRoute
              path="/company-name-change"
              component={NameChange}
              {...authProps}
            />
            <PrivateRoute
              path="/company-address-change"
              component={AddressChange}
              {...authProps}
            />
            <PrivateRoute
              path="/company-directors-change"
              component={DirectorsChange}
              {...authProps}
            />
            <PrivateRoute
              path="/vat-registration"
              component={VATRegistration}
              {...authProps}
            />
            <PrivateRoute
              path="/import-export-license"
              component={ImportExportLicense}
              {...authProps}
            />
            <PrivateRoute
              path="/paye-registration"
              component={PayeRegistration}
              {...authProps}
            />
            <PrivateRoute
              path="/uif-registration"
              component={UifRegistration}
              {...authProps}
            />
            <PrivateRoute
              path="/trademark"
              component={Trademark}
              {...authProps}
            />
            <PrivateRoute
              path="/incorporation-documents"
              component={IncorporationDocuments}
              {...authProps}
            />
            <PrivateRoute
              path="/beneficial-ownership"
              component={BeneficialOwnership}
              {...authProps}
            />
            <PrivateRoute
              path="/deregistration"
              component={Deregistration}
              {...authProps}
            />
            <PrivateRoute
              path="/reinstatement"
              component={Reinstatement}
              {...authProps}
            />
            <PrivateRoute
              path="/csd-registration"
              component={CsdRegistration}
              {...authProps}
            />
            <PrivateRoute path="/coid" component={Coid} {...authProps} />
            <PrivateRoute
              path="/coid-renewal"
              component={LogsRenewal}
              {...authProps}
            />
            <PrivateRoute path="/cidb" component={Cidb} {...authProps} />
            <PrivateRoute
              path="/share-certificates"
              component={Shares}
              {...authProps}
            />
            <PrivateRoute
              path="/registered-rep"
              component={RegisteredRep}
              {...authProps}
            />

            {/* REFUNDS */}
            <PrivateRoute path="/refund" component={Refund} {...authProps} />

            {/* ACCOUNTING */}
            <PrivateRoute
              path="/accounting"
              component={Accounting}
              {...authProps}
            />

            <Route path="*" component={NotFound} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Sentry.ErrorBoundary>
    </main>
  );
};

export default Routes;
