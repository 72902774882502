import axios from 'axios';

const BASEURL = process.env.REACT_APP_API_URL;

export const getDirectors = (currentCompanyId, token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${BASEURL}/companys/companys/${currentCompanyId}/directors/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const getDirector = (
  inviteID // need to change thiss
) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${BASEURL}/companys/membership/${inviteID}/`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const updateDirector = (director, token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'patch',
      url: `${BASEURL}/accounts/users/current/profile/`,
      data: director,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const addDirector = (payload, currentCompanyId, token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${BASEURL}/companys/companys/${currentCompanyId}/add-director/`,
      data: payload,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const addDirectors = (payload, currentCompanyId, token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${BASEURL}/companys/companys/${currentCompanyId}/add-directors/`,
      data: payload,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const removeDirector = (inviteId, token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: `${BASEURL}/companys/membership/${inviteId}/force_remove/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const acceptMembership = (inviteId, key, signName, headers) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'patch',
      url: `${BASEURL}/companys/membership/${inviteId}/sign-accept/`,
      data: {
        signature_url: key,
        sign_name: signName,
        headers,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const submitKyc = (documents, token, inviteId) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'patch',
      url: `${BASEURL}/companys/membership/${inviteId}/kyc/`,
      data: documents,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const signAccept = (inviteId, key) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'patch',
      url: `${BASEURL}/companys/membership/${inviteId}/sign-accept-redeem/`,
      data: {
        signature_url: key,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const resendInviteLink = (inviteId, token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${BASEURL}/companys/membership/${inviteId}/resend_invite_link/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const updateEmail = (director, inviteId, token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${BASEURL}/companys/membership/${inviteId}/update_email/`,
      headers: { Authorization: `Token ${token}` },
      data: director,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });
