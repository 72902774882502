import React, { Component } from 'react';
import { Alert } from 'antd';
import { PropTypes, instanceOf } from 'prop-types';
import qs from 'qs';
import { withCookies, Cookies } from 'react-cookie';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';

import { analytics } from 'common/analytics/analytics';
import { LOGIN_SUCCESS, LOGIN_ERROR } from 'common/analytics/events';
import LoadingPage from 'common/components/LoadingPage';
import { clearUserData } from 'common/lib/user';
import { setUserCurrentCompanyById as setUserCurrentCompanyByIdAction } from 'common/reducers/companies';
import { fetchUserAndCompanies as fetchUserAndCompaniesAction } from 'common/reducers/user';
import { handleAuthRedirectUrl } from 'common/utils/authUtils';
import { setToken } from 'common/utils/cookiesUtils';

const StyledAlert = styled(Alert)`
  max-width: 500px;
  margin: 3em auto;
`;

class TokenLogin extends Component {
  state = {
    checking: true,
    invalidToken: false,
  };

  componentDidMount() {
    clearUserData();
    this.handleFetchData();
  }

  handleFetchData = async () => {
    const {
      cookies,
      match,
      history,
      location,
      fetchUserAndCompanies,
      setUserCurrentCompanyById,
    } = this.props;
    const { token } = match.params;
    try {
      const query = location.search;
      const values = qs.parse(query, { ignoreQueryPrefix: true });
      setToken(cookies, token);
      // ? Check here if we have query parameters to fetch a specific company
      if (values.co) {
        await setUserCurrentCompanyById(values.co, token);
      }

      const response = await fetchUserAndCompanies(token);
      const user = response[0];
      analytics.track(LOGIN_SUCCESS, user);

      const company = response[1];
      const companies = response[2];
      const order = response[3];

      const companyState = company.state;
      const orderState = order.state;

      return history.push(
        handleAuthRedirectUrl({ companyState, orderState, query, companies })
      );
    } catch (error) {
      analytics.track(LOGIN_ERROR);
      return this.setState({
        checking: false,
        invalidToken: true,
      });
    }
  };

  render() {
    const { checking, invalidToken } = this.state;
    return (
      <div>
        {checking && <LoadingPage />}
        {invalidToken && (
          <StyledAlert
            message="Login failed"
            description={
              <div>
                Your link is either incorrect or expired. Please{' '}
                <Link to="/login">request a new login link</Link>.
              </div>
            }
            type="error"
          />
        )}
      </div>
    );
  }
}

TokenLogin.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  cookies: instanceOf(Cookies).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  fetchUserAndCompanies: PropTypes.func.isRequired,
  setUserCurrentCompanyById: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

const connectedTokenLogin = connect(
  (state) => ({
    token: state.user.token,
  }),
  {
    fetchUserAndCompanies: fetchUserAndCompaniesAction,
    setUserCurrentCompanyById: setUserCurrentCompanyByIdAction,
  }
)(TokenLogin);

export default withCookies(withRouter(connectedTokenLogin));
