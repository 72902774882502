import axios from 'axios';

const BASEURL = process.env.REACT_APP_API_URL;

export const getService = (token, id) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${BASEURL}/services/companyservices/${id}/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const getServices = (token) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${BASEURL}/services/companyservices/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('Error fetching and parsing data', error));
      });
  });

export const requestService = (token, id) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${BASEURL}/services/companyservices/${id}/request_service/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
