import { Layout, Row, Col } from 'antd';
import styled from 'styled-components';

import Contact from './Contact';
import Copyright from './Copyright';
import Disclaimer from './Disclaimer';
import FooterMenu from './FooterMenu';
import MadeIn from './MadeIn';

const { Footer } = Layout;

const Wrapper = styled(Footer)`
  background: white !important;
  border-top: 1px solid #e9eaeb;
  padding: 24px 60px !important;
  z-index: 3;
  @media (max-width: 768px) {
    line-height: 1.8em;
    padding: 24px 16px !important;
  }
`;

const Foot = () => {
  return (
    <Wrapper>
      <Row>
        <Col xs={24} md={12}>
          <Contact />
        </Col>
        <Col xs={24} md={12}>
          <FooterMenu />
        </Col>
      </Row>
      <Disclaimer />
      <Row justify="space-between">
        <Col md={12}>
          <Copyright />
        </Col>
        <Col md={12}>
          <MadeIn />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Foot;
