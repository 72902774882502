import { getPublicSuffix } from 'tldjs';

export const isValidEmail = (email: string) => /\S+@\S+\.\S+/.test(email);

export const isValidTopLevelDomain = (email: string) => {
  let result = true;
  const array = email.split('@')[1].split('.').splice(1);
  // eslint-disable-next-line
  array.map((i) => {
    if (i.length > 3) {
      result = false;
    }
  });
  return result;
};

export const includesGovchain = (email: string) => {
  let response = false;
  const formattedEmail = email.toLowerCase();
  if (formattedEmail.includes('govchain')) {
    response = true;
  }
  return response;
};

export const validateEmailAddress = (rule: any, value: string) => {
  if (!rule.required && !value) {
    return Promise.resolve();
  }
  if (!isValidEmail(value)) {
    return Promise.reject(new Error('Please input a valid email'));
  }
  if (includesGovchain(value)) {
    return Promise.reject(new Error('You cannot use a Govchain email address'));
  }
  if (!isValidTopLevelDomain(value)) {
    const topLevelDomain = getPublicSuffix(value);
    return Promise.reject(
      new Error(
        `Unfortunately .${topLevelDomain} is not accepted by the government yet :( Please enter a different email address like .com, .org or .co.za.`
      )
    );
  }
  return Promise.resolve();
};

export const normalizeEmail = (value: string) =>
  value && value.replace(/ /g, '').trim().toLowerCase();
