import humps from 'humps';
import { getDirectors } from '../lib/directorServices';

const initialState = {
  list: [],
};

export const DIRECTORS_LOAD = 'DIRECTORS_LOAD';

export const loadDirectors = (directors) => ({
  type: DIRECTORS_LOAD,
  payload: directors,
});

export const fetchDirectors = (currentCompanyId, token) => {
  return async (dispatch) => {
    try {
      const directors = await getDirectors(currentCompanyId, token);
      const formattedDirectors = humps.camelizeKeys(directors);
      await dispatch(loadDirectors(formattedDirectors));
      return formattedDirectors;
    } catch (error) {
      return Error('Error fetching and parsing data', error);
    }
  };
};

const directors = (state = initialState, action) => {
  switch (action.type) {
    case DIRECTORS_LOAD:
      return { ...state, list: action.payload };
    default:
      return state;
  }
};

export default directors;
