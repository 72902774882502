import React from 'react';
import { MailOutlined } from '@ant-design/icons';
import { Form, Input, Button, Alert } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import analytics from 'common/analytics/analytics';
import {
  LOGIN_FORM_SUBMITTED,
  LOGIN_FORM_ERROR,
} from 'common/analytics/events';

const FormItem = Form.Item;

const AlertWrapper = styled.div`
  margin-bottom: 2em !important;
`;

const ButtonsWrapper = styled.div`
  button {
    width: 100%;
  }
`;

export const normalizeEmail = (value: string) =>
  value && value.replace(/ /g, '').trim().toLowerCase();

export type LoginFormProps = {
  onSubmit: (values: { email: string }) => void;
  errorMessage?: React.ReactNode;
  successMessage?: React.ReactNode;
  isSubmitting?: boolean;
};

export const LoginForm: React.FC<LoginFormProps> = ({
  onSubmit,
  errorMessage,
  successMessage,
  isSubmitting = false,
}) => {
  const [form] = Form.useForm();

  const onFinish = (values: { email: string }) => {
    analytics.track(LOGIN_FORM_SUBMITTED, values);
    onSubmit(values);
  };

  const onFinishFailed = ({
    errorFields,
  }: {
    errorFields: { name: string }[];
  }) => {
    form.scrollToField(errorFields[0].name);
    analytics.track(LOGIN_FORM_ERROR, errorFields);
  };

  return (
    <Form
      onFinish={onFinish}
      // @ts-ignore
      onFinishFailed={onFinishFailed}
      className="login-form"
      labelCol={{ span: 24 }}
      form={form}
    >
      <FormItem
        name="email"
        label="Email Address"
        rules={[
          { required: true },
          {
            pattern: /\S+@\S+\.\S+/,
            message: 'Please input a valid email!',
          },
        ]}
        validateFirst
        normalize={normalizeEmail}
      >
        <Input
          size="large"
          prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          autoComplete="email"
        />
      </FormItem>
      {successMessage && (
        <AlertWrapper>
          <Alert message={successMessage} type="success" />
        </AlertWrapper>
      )}
      {errorMessage && (
        <AlertWrapper>
          <Alert message={errorMessage} type="error" />
        </AlertWrapper>
      )}
      {!successMessage && (
        <ButtonsWrapper>
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              size="large"
              loading={isSubmitting}
            >
              {isSubmitting ? 'Loading' : 'Log in'}
            </Button>
          </FormItem>
          <p>
            Don’t have an account? <Link to="/signup">Sign up</Link>
          </p>
        </ButtonsWrapper>
      )}
    </Form>
  );
};

export default LoginForm;
